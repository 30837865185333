// Simple keyword-based prompt analyzer
class PromptAnalyzer {
  constructor() {
    this.importantKeywords = [
      'person', 'man', 'woman', 'child', 'animal', 'landscape', 'building',
      'portrait', 'action', 'emotion', 'color', 'style', 'time', 'place'
    ];
    this.tokenWeights = {
      'person': 0.8,
      'man': 0.7,
      'woman': 0.7,
      'child': 0.7,
      'animal': 0.6,
      'landscape': 0.6,
      'building': 0.6,
      'portrait': 0.7,
      'action': 0.6,
      'emotion': 0.6,
      'color': 0.5,
      'style': 0.5,
      'time': 0.5,
      'place': 0.5
    };
  }

  analyzePrompt(prompt) {
    const phrases = prompt.toLowerCase().split(',').map(phrase => phrase.trim());
    const analyzedPhrases = phrases.map(phrase => ({
      phrase,
      importance: this.getPhraseImportance(phrase)
    }));
    
    return analyzedPhrases.sort((a, b) => b.importance - a.importance);
  }

  getPhraseImportance(phrase) {
    const words = phrase.split(/\s+/);
    const importantWords = words.filter(word => this.importantKeywords.includes(word));
    
    if (importantWords.length > 0) {
      return Math.max(...importantWords.map(word => this.tokenWeights[word] || 0.4));
    }
    
    // If no important words found, assign importance based on phrase length
    return Math.min(0.8, 0.3 + (0.1 * words.length));
  }
}

export default new PromptAnalyzer();
