import React, { useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { keyframes, styled } from '@mui/material/styles';

const meteorAnimation = keyframes`
  0% {
    transform: translateY(0) translateX(0) rotate(-45deg);  // Start diagonal
    opacity: 0;  // Start invisible
  }
  90% {
    opacity: 1;  // Fade in by 20% of the animation duration
  }
  100% {
    transform: translateY(100vh) translateX(-100vw) rotate(-45deg);  // End diagonal
    opacity: 0;
  }
`;

const MeteorBackground = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  pointerEvents: 'none',
}));

const Meteor = styled('div')(({ theme, delay, duration, size, top, left }) => ({
  position: 'absolute',
  top: top,
  left: left,
  width: `${size}px`,
  height: `${size / 10}px`,
  background: `linear-gradient(to right, rgba(255, 255, 255, 0.4), transparent)`,
  borderRadius: '50%',
  animation: `${meteorAnimation} ${duration}s linear infinite`,
  animationDelay: `${delay}s`,
  transformOrigin: 'left down',  // Set the rotation origin
  opacity: 0,  // Initially invisible
}));

const Meteors = ({ count = 30 }) => {
  const meteorsRef = useRef([]);

  useEffect(() => {
    meteorsRef.current = Array(count).fill().map(() => {
      const size = Math.random() * 30 + 50;
      const duration = Math.random() * 3 + 5; // Speed variation
      const delay = Math.random() * 2; // Small delay to simulate multiple meteors at once
      const top = `${Math.random() * 75}vh`; // Start from random vertical position
      const left = `${Math.random() * 75}vw`; // Start from random horizontal position

      return { size, duration, delay, top, left };
    });
  }, [count]);

  return (
    <MeteorBackground>
      {meteorsRef.current.map((meteor, index) => (
        <Meteor
          key={index}
          size={meteor.size}
          duration={meteor.duration}
          delay={meteor.delay}
          top={meteor.top}
          left={meteor.left}
        />
      ))}
    </MeteorBackground>
  );
};

export default Meteors;
