import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Box, Slider, Grid, Typography, ToggleButton, ToggleButtonGroup, Modal, Card, CardContent, CardMedia, CircularProgress, IconButton, useMediaQuery, useTheme, Button, Collapse, Tooltip, Fade, Checkbox } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import ClearIcon from '@mui/icons-material/Clear';
import { Helmet } from 'react-helmet-async';
import { supabase, isAdmin } from '../supabaseClient';
import { keyframes, styled } from '@mui/system';
import debounce from 'lodash/debounce';
import { sendNotification, fetchUserNames } from '../utils/notifications';
import Meteors from './Meteors';
import Particles from './Particles';

const IMAGES_PER_PAGE = 20;
const INITIAL_LOAD = 60; // Increased initial load to reduce the chance of gaps
const DEBOUNCE_DELAY = 100; // Reduced debounce delay for quicker response

const heartBeat = keyframes`
  0% {
    transform: scale(1);
  }
  14% {
    transform: scale(1.3);
  }
  28% {
    transform: scale(1);
  }
  42% {
    transform: scale(1.3);
  }
  70% {
    transform: scale(1);
  }
`;

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: expand ? 'rotate(180deg)' : 'rotate(0deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const StyledImage = styled('img')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  transition: 'filter 0.3s ease-in-out',
  '&:hover': {
    filter: 'brightness(1.1) contrast(1.1)',
  },
}));

const PublicGallery = ({ session }) => {
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [images, setImages] = useState([]);
  const [sortBy, setSortBy] = useState('hot');
  const [imagesPerRow, setImagesPerRow] = useState(4);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loadingFullImage, setLoadingFullImage] = useState(false);
  const [visibleImages, setVisibleImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [likedImages, setLikedImages] = useState({});
  const [viewCounts, setViewCounts] = useState(() => {
    const saved = localStorage.getItem('viewCounts');
    return saved ? JSON.parse(saved) : {};
  });
  const containerRef = useRef(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [expandedMetadata, setExpandedMetadata] = useState(false);
  const [selectionMode, setSelectionMode] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      // You can add a notification here if you want
      console.log('Metadata copied to clipboard');
    });
  };

  useEffect(() => {
    setPage(1);
    setImages([]);
    fetchImages(1, INITIAL_LOAD);
  }, [sortBy]);

  useEffect(() => {
    if (page > 1) {
      fetchImages(page, IMAGES_PER_PAGE);
    }
  }, [page]);

  useEffect(() => {
    if (session) {
      fetchLikedImages();
      checkAdminStatus();
    }
  }, [session]);

  const checkAdminStatus = async () => {
    if (session) {
      const adminStatus = await isAdmin(session.user.id);
      setIsUserAdmin(adminStatus);
    }
  };

  const fetchLikedImages = async () => {
    const { data, error } = await supabase
      .from('likes')
      .select('image_id')
      .eq('user_id', session.user.id);

    if (error) {
      // console.error('Error fetching liked images:', error);
    } else {
      const likedImageIds = data.reduce((acc, item) => {
        acc[item.image_id] = true;
        return acc;
      }, {});
      setLikedImages(likedImageIds);
    }
  };

  useEffect(() => {
    localStorage.setItem('viewCounts', JSON.stringify(viewCounts));
  }, [viewCounts]);

  const fetchImages = useCallback(async (pageNumber = 1, limit = IMAGES_PER_PAGE) => {
    if (pageNumber === 1) setLoading(true);
    setIsLoadingMore(true);
    
    let query = supabase
      .from('public_images')
      .select('*')
      .range((pageNumber - 1) * limit, pageNumber * limit - 1);

    if (sortBy === 'hot') {
      query = query.order('likes', { ascending: false });
    } else if (sortBy === 'new') {
      query = query.order('created_at', { ascending: false });
    } else {
      query = query.order('views', { ascending: false });
    }

    let { data, error } = await query;

    if (error) {
      // console.error('Error fetching images:', error);
    } else {
      const updatedImages = data.map((image) => ({
        ...image,
        isLiked: likedImages[image.id] || false,
        views: viewCounts[image.id] || image.views
      }));
      
      setImages(prevImages => {
        if (pageNumber === 1) {
          return updatedImages;
        } else {
          // Remove duplicates when appending new images
          const newImageIds = new Set(updatedImages.map(img => img.id));
          const filteredPrevImages = prevImages.filter(img => !newImageIds.has(img.id));
          return [...filteredPrevImages, ...updatedImages];
        }
      });
      
      setHasMore(data.length === limit);
    }
    setLoading(false);
    setIsLoadingMore(false);
  }, [sortBy, likedImages, viewCounts]);

  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const loadMoreImages = useCallback(() => {
    if (!loading && !isLoadingMore && hasMore) {
      setPage(prevPage => prevPage + 1);
    }
  }, [loading, isLoadingMore, hasMore]);

  const debouncedLoadMoreImages = useCallback(
    debounce(loadMoreImages, DEBOUNCE_DELAY),
    [loadMoreImages]
  );

  const handleSortChange = useCallback((event, newSortBy) => {
    if (newSortBy !== null) {
      setSortBy(newSortBy);
    }
  }, []);

  const handleSliderChange = useCallback((event, newValue) => {
    setImagesPerRow(newValue);
  }, []);

  const handleImageClick = useCallback(async (image) => {
    setSelectedImage(image);
    setLoadingFullImage(true);

    // Increment view count locally
    const newViewCount = (viewCounts[image.id] || image.views) + 1;
    setViewCounts(prev => ({ ...prev, [image.id]: newViewCount }));

    // Update view count in the database
    const { data, error } = await supabase
      .from('public_images')
      .update({ views: newViewCount })
      .eq('id', image.id);

    if (error) {
      // console.error('Error updating view count:', error);
    } else {
      setImages(prevImages =>
        prevImages.map(img =>
          img.id === image.id ? { ...img, views: newViewCount } : img
        )
      );
    }
  }, [viewCounts]);

  const handleDeleteImage = async (imageIds) => {
    if (!isUserAdmin) return;

    const confirmDelete = window.confirm(`Are you sure you want to delete ${imageIds.length} image(s)?`);
    if (!confirmDelete) return;

    try {
      // Delete the images from Supabase
      const { error } = await supabase
        .from('public_images')
        .delete()
        .in('id', imageIds);

      if (error) throw error;

      // Remove the images from the local state
      setImages(prevImages => prevImages.filter(img => !imageIds.includes(img.id)));
      
      // Close the modal if the deleted image was selected
      if (selectedImage && imageIds.includes(selectedImage.id)) {
        setSelectedImage(null);
      }

      // Clear selection
      setSelectedImages([]);
      setSelectionMode(false);

      // Refetch images to ensure the gallery is up-to-date
      fetchImages(1, INITIAL_LOAD);

      alert('Image(s) deleted successfully.');
    } catch (error) {
      console.error('Error deleting images:', error);
      alert('Failed to delete image(s). Please try again.');
    }
  };

  const handleSelectImage = (imageId) => {
    setSelectedImages(prev => {
      if (prev.includes(imageId)) {
        return prev.filter(id => id !== imageId);
      } else {
        return [...prev, imageId];
      }
    });
  };

  const handleSelectAll = () => {
    if (selectedImages.length === images.length) {
      setSelectedImages([]);
    } else {
      setSelectedImages(images.map(img => img.id));
    }
  };

  const handleClearSelection = () => {
    setSelectedImages([]);
    setSelectionMode(false);
  };

  const handleLike = useCallback(async (event, image) => {
    event.stopPropagation();
    if (!session) {
      alert('Please log in to like images');
      return;
    }
    const newLikeState = !likedImages[image.id];
    const likeDelta = newLikeState ? 1 : -1;

    try {
      // Update likes in public_images table
      const { data: imageData, error: imageError } = await supabase
        .from('public_images')
        .update({ likes: image.likes + likeDelta })
        .eq('id', image.id);

      if (imageError) throw imageError;

      // Update or delete record in likes table
      if (newLikeState) {
        const { error: likeError } = await supabase
          .from('likes')
          .insert({ user_id: session.user.id, image_id: image.id });

        if (likeError) throw likeError;

        // Fetch the user's name
        const userNames = await fetchUserNames([session.user.id]);
        const userName = userNames[session.user.id] || 'A user';

        // Send notification to the image owner
        await sendNotification(image.user_id, {
          type: 'like',
          message: `${userName} liked your image`,
          image_id: image.id
        });
      } else {
        const { error: unlikeError } = await supabase
          .from('likes')
          .delete()
          .match({ user_id: session.user.id, image_id: image.id });

        if (unlikeError) throw unlikeError;
      }

      // Update local state
      setImages(prevImages =>
        prevImages.map(img =>
          img.id === image.id ? { ...img, likes: img.likes + likeDelta, isLiked: newLikeState } : img
        )
      );
      setLikedImages(prev => ({ ...prev, [image.id]: newLikeState }));
    } catch (error) {
      // console.error('Error handling like:', error);
      alert('An error occurred while processing your like. Please try again.');
    }
  }, [likedImages, session, sendNotification, fetchUserNames]);

  const handleImageLoad = useCallback(() => {
    setLoadingFullImage(false);
  }, []);

  const handleCloseModal = useCallback(() => {
    setSelectedImage(prevImage => {
      if (prevImage) {
        setTimeout(() => setSelectedImage(null), 300); // Delay to allow fade-out animation
        return { ...prevImage, closing: true };
      }
      return null;
    });
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setVisibleImages((prevVisible) => {
              const newVisible = new Set(prevVisible);
              newVisible.add(entry.target.dataset.id);
              return Array.from(newVisible);
            });
          } else {
            setVisibleImages((prevVisible) => {
              const newVisible = new Set(prevVisible);
              newVisible.delete(entry.target.dataset.id);
              return Array.from(newVisible);
            });
          }
        });
      },
      { rootMargin: '100px' }
    );

    const imageElements = containerRef.current?.querySelectorAll('[data-id]');
    imageElements?.forEach((element) => {
      observer.observe(element);
    });

    // Create a separate observer for infinite scrolling
    const loadMoreObserver = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !isLoadingMore) {
          loadMoreImages();
        }
      },
      { rootMargin: '300px', threshold: 0.1 }
    );

    const loadMoreTrigger = document.querySelector('#load-more-trigger');
    if (loadMoreTrigger) {
      loadMoreObserver.observe(loadMoreTrigger);
    }

    return () => {
      observer.disconnect();
      loadMoreObserver.disconnect();
    };
  }, [images, debouncedLoadMoreImages]);

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "ImageGallery",
    "name": "FluxForge Public Gallery",
    "description": "A collection of AI-generated images created by the FluxForge community",
    "image": images.slice(0, 5).map(img => img.url),
    "url": "https://fluxforge.app/"
  };

  return (
    <Box sx={{ padding: 2 }} ref={containerRef}>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, flexWrap: 'wrap' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: { xs: 2, md: 0 } }}>
          <ToggleButtonGroup
            value={sortBy}
            exclusive
            onChange={handleSortChange}
            aria-label="sort images"
          >
            <ToggleButton value="hot" aria-label="hot">
              Hot
            </ToggleButton>
            <ToggleButton value="new" aria-label="new">
              New
            </ToggleButton>
            <ToggleButton value="popular" aria-label="most popular">
              Most Popular
            </ToggleButton>
          </ToggleButtonGroup>
          {isUserAdmin && (
            <Button
              variant="outlined"
              onClick={() => setSelectionMode(!selectionMode)}
              sx={{ ml: 2 }}
            >
              {selectionMode ? 'Exit Selection' : 'Select Images'}
            </Button>
          )}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {!isMobile && (
            <Box sx={{ width: 200, mr: 2 }}>
              <Typography id="images-per-row-slider" gutterBottom>
                Images per row
              </Typography>
              <Slider
                value={imagesPerRow}
                onChange={handleSliderChange}
                aria-labelledby="images-per-row-slider"
                step={1}
                marks
                min={2}
                max={6}
              />
            </Box>
          )}
          {selectionMode && (
            <>
              <Tooltip title="Select All">
                <IconButton onClick={handleSelectAll}>
                  <SelectAllIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Clear Selection">
                <IconButton onClick={handleClearSelection}>
                  <ClearIcon />
                </IconButton>
              </Tooltip>
              <Button
                variant="contained"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={() => handleDeleteImage(selectedImages)}
                disabled={selectedImages.length === 0}
              >
                Delete Selected ({selectedImages.length})
              </Button>
            </>
          )}
        </Box>
      </Box>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 450 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={2}>
          {images.map((image, index) => (
            <Grid item xs={12} sm={6} md={12 / imagesPerRow} key={image.id}>
              <Box
                sx={{
                  position: 'relative',
                  paddingTop: '100%',
                  cursor: selectionMode ? 'default' : 'pointer',
                  overflow: 'hidden',
                  borderRadius: theme.shape.borderRadius,
                  boxShadow: theme.shadows[4],
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': {
                    transform: selectionMode ? 'none' : 'scale(1.05)',
                  },
                }}
                onClick={() => selectionMode ? handleSelectImage(image.id) : handleImageClick(image)}
                data-id={image.id}
              >
                {selectionMode && isUserAdmin && (
                  <Checkbox
                    checked={selectedImages.includes(image.id)}
                    onChange={() => handleSelectImage(image.id)}
                    sx={{
                      position: 'absolute',
                      top: 8,
                      left: 8,
                      zIndex: 1,
                      color: 'white',
                      '&.Mui-checked': {
                        color: 'primary.main',
                      },
                    }}
                  />
                )}
                {visibleImages.includes(image.id) && (
                  <Fade in={true} timeout={500}>
                    <Box>
                      <StyledImage
                        src={image.url}
                        alt={`Generated image ${index + 1}`}
                        loading="lazy"
                      />
                      <IconButton
                        onClick={(e) => handleLike(e, image)}
                        sx={{
                          position: 'absolute',
                          bottom: 8,
                          right: 8,
                          color: likedImages[image.id] ? 'red' : 'white',
                          backgroundColor: 'rgba(0, 0, 0, 0.5)',
                          '&:hover': {
                            backgroundColor: session ? 'rgba(0, 0, 0, 0.7)' : 'rgba(0, 0, 0, 0.5)',
                          },
                          animation: likedImages[image.id] ? `${heartBeat} 1.5s ease-in-out` : 'none',
                          opacity: session ? 1 : 0.5,
                          cursor: session ? 'pointer' : 'not-allowed',
                        }}
                      >
                        {likedImages[image.id] ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                      </IconButton>
                    </Box>
                  </Fade>
                )}
              </Box>
            </Grid>
          ))}
        </Grid>
      )}
      {hasMore && (
        <Box id="load-more-trigger" sx={{ height: '1px', mt: 2 }} />
      )}
      <Modal
        open={!!selectedImage}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleCloseModal();
          }
        }}
        aria-labelledby="image-modal-title"
        aria-describedby="image-modal-description"
        closeAfterTransition
      >
        <Fade in={!!selectedImage && !selectedImage.closing} timeout={300}>
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: 800,
            maxHeight: '90vh',
            bgcolor: 'rgba(0, 0, 0, 0.9)',
            boxShadow: 24,
            p: 4,
            borderRadius: theme.shape.borderRadius,
            overflow: 'auto',
          }}>
            <IconButton
              onClick={handleCloseModal}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'white',
              }}
            >
              <CloseIcon />
            </IconButton>
            <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, overflow: 'hidden', pointerEvents: 'none' }}>
              <Meteors />
              <Particles />
            </Box>
            <Box sx={{ position: 'relative', zIndex: 1 }}>
            {loadingFullImage && (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
              <CircularProgress />
            </Box>
          )}
          <CardMedia
            component="img"
            image={selectedImage?.url}
            alt={selectedImage?.prompt}
            sx={{ 
              maxHeight: '70vh', 
              objectFit: 'contain', 
              display: loadingFullImage ? 'none' : 'block',
              borderRadius: theme.shape.borderRadius,
              mb: 2,
            }}
            onLoad={handleImageLoad}
          />
          <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography id="image-modal-title" variant="h6" component="h2">
                Image Details
              </Typography>
              <Tooltip title="Copy all metadata">
                <IconButton onClick={() => copyToClipboard(JSON.stringify(selectedImage, null, 2))}>
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Typography id="image-modal-description" sx={{ mb: 2 }}>
              Prompt: {selectedImage?.prompt}
            </Typography>
            <ExpandMore
              expand={expandedMetadata}
              onClick={() => setExpandedMetadata(!expandedMetadata)}
              aria-expanded={expandedMetadata}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
            <Collapse in={expandedMetadata} timeout="auto" unmountOnExit>
              <Typography>
                Character Prompt: {selectedImage?.character_prompt || 'None'}
              </Typography>
              <Typography>
                Outfit Prompt: {selectedImage?.outfit_prompt || 'None'}
              </Typography>
              <Typography>
                Background Prompt: {selectedImage?.background_prompt || 'None'}
              </Typography>
              <Typography>
                Dialogue Balloon: {selectedImage?.dialogue_balloon_prompt || 'None'}
              </Typography>
              <Typography>
                Artist & Style: {selectedImage?.artist_style_prompt || 'None'}
              </Typography>
              <Typography>
                Negative Prompt: {selectedImage?.negative_prompt || 'None'}
              </Typography>
              <Typography>
                Likes: {selectedImage?.likes}
              </Typography>
              <Typography>
                Views: {selectedImage?.views}
              </Typography>
              <Typography>
                Created At: {new Date(selectedImage?.created_at).toLocaleString()}
              </Typography>
            </Collapse>
            {isUserAdmin && (
              <Button
                startIcon={<DeleteIcon />}
                variant="contained"
                color="error"
                onClick={() => handleDeleteImage(selectedImage.id)}
                sx={{ mt: 2 }}
              >
                Delete Image
              </Button>
            )}
          </CardContent>
          </Box>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default React.memo(PublicGallery);
