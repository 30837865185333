import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { Box, Modal, IconButton, useMediaQuery, useTheme, Button, Typography, CircularProgress, Fade, Zoom } from '@mui/material';
import { keyframes, styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { supabase } from '../supabaseClient';

const subtleHoverEffect = keyframes`
  0% { box-shadow: 0 0 2px rgba(200, 200, 200, 0.3); }
  50% { box-shadow: 0 0 8px rgba(200, 200, 200, 0.5); }
  100% { box-shadow: 0 0 2px rgba(200, 200, 200, 0.3); }
`;

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    boxShadow: '0 0 10px rgba(76, 175, 80, 0.5)',
  },
}));

const fadeTransition = {
  enter: 300,
  exit: 200,
};

const zoomTransition = {
  enter: 300,
  exit: 200,
};

const ImageSidebar = ({ imagePairs, refreshTrigger, session }) => {
  const [loadingFullImage, setLoadingFullImage] = useState(false);
  const [selectedImagePair, setSelectedImagePair] = useState(null);
  const [showSecondImage, setShowSecondImage] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const containerRef = useRef(null);
  const imageRef = useRef(null);

  useEffect(() => {
    setLoading(true);
    // Simulate loading delay
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => clearTimeout(timer);
  }, [imagePairs, refreshTrigger]);


  const allImagePairs = useMemo(() => {
    // Remove duplicates
    const uniquePairs = imagePairs.reduce((acc, current) => {
      const x = acc.find(item => item[0] === current[0] && item[1] === current[1]);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    return uniquePairs;
  }, [imagePairs]);

  const [pairsPerPage, setPairsPerPage] = useState(isMobile ? 3 : 4);

  const handleImagePairClick = useCallback((imagePair) => {
    setSelectedImagePair(imagePair);
    setLoadingFullImage(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setSelectedImagePair(null);
    setShowSecondImage(false);
    setLoadingFullImage(false);
  }, []);

  const handleToggleImage = useCallback(() => {
    setShowSecondImage((prev) => !prev);
    setLoadingFullImage(true);
  }, []);

  const handleImageLoad = useCallback(() => {
    setLoadingFullImage(false);
  }, []);

  const handleZoomIn = useCallback(() => {
    setZoomLevel((prevZoom) => Math.min(prevZoom + 0.1, 3));
  }, []);

  const handleZoomOut = useCallback(() => {
    setZoomLevel((prevZoom) => Math.max(prevZoom - 0.1, 0.5));
  }, []);

  const handleFullScreen = useCallback(() => {
    if (imageRef.current) {
      if (!document.fullscreenElement) {
        imageRef.current.requestFullscreen();
        setIsFullScreen(true);
      } else {
        document.exitFullscreen();
        setIsFullScreen(false);
      }
    }
  }, []);

  useEffect(() => {
    const handleFullScreenChange = () => {
      setIsFullScreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullScreenChange);
    return () => document.removeEventListener('fullscreenchange', handleFullScreenChange);
  }, []);

  const handlePrevious = useCallback(() => {
    setStartIndex((prevIndex) => Math.max(0, prevIndex - pairsPerPage));
  }, [pairsPerPage]);

  const handleNext = useCallback(() => {
    setStartIndex((prevIndex) => Math.min(allImagePairs.length - pairsPerPage, prevIndex + pairsPerPage));
  }, [allImagePairs.length, pairsPerPage]);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const containerSize = isMobile ? containerRef.current.offsetWidth : containerRef.current.offsetHeight;
        const imageSize = 70; // Including margin
        const newPairsPerPage = Math.floor(containerSize / imageSize);
        setPairsPerPage(Math.max(1, newPairsPerPage));
        setStartIndex(0); // Reset to the first page when resizing
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [isMobile]);

  const visibleImagePairs = useMemo(() => 
    allImagePairs.slice(startIndex, startIndex + pairsPerPage),
    [allImagePairs, startIndex, pairsPerPage]
  );

  useEffect(() => {
    setLoading(true);
    // Simulate loading delay
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => clearTimeout(timer);
  }, [startIndex, pairsPerPage]);

  return (
    <Box
      ref={containerRef}
      sx={{
        position: 'fixed',
        left: 0,
        [isMobile ? 'bottom' : 'top']: isMobile ? '50px' : '64px', // Increased space from bottom on mobile
        [isMobile ? 'top' : 'bottom']: 'auto',
        width: isMobile ? '100%' : '80px',
        height: isMobile ? '70px' : 'calc(100% - 64px - 50px)', // Reduced height on mobile
        zIndex: 1000,
        background: 'linear-gradient(to bottom, #1a1a1a, #000000)',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          opacity: 0.05,
          backgroundImage: 'url("data:image/svg+xml,%3Csvg width="20" height="20" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M0 0h20v20H0z" fill="%23ffffff" fill-opacity="0.1"/%3E%3C/svg%3E")',
          pointerEvents: 'none',
        },
        display: 'flex',
        flexDirection: isMobile ? 'row' : 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px',
        zIndex: 1000,
      }}
    >
      <Button
        onClick={handlePrevious}
        disabled={startIndex === 0}
        sx={{
          minWidth: 'auto',
          padding: '5px',
          position: 'absolute',
          [isMobile ? 'left' : 'top']: 5,
        }}
      >
        <ArrowBackIosNewIcon fontSize="small" />
      </Button>

      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'row' : 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
          overflow: 'hidden',
          paddingTop: isMobile ? 0 : '30px',
          paddingBottom: isMobile ? 0 : '30px',
          paddingLeft: isMobile ? '30px' : 0,
          paddingRight: isMobile ? '30px' : 0,
        }}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          visibleImagePairs.map((imagePair, index) => (
            <Box
              key={`image-pair-${startIndex + index}`}
              sx={{
                width: '51px', // 15% smaller
                height: '51px', // 15% smaller
                borderRadius: '13px', // Slightly reduced to maintain proportions
                overflow: 'hidden',
                margin: '7px', // Increased margin to maintain overall spacing
                cursor: 'pointer',
                transition: 'all 0.3s ease',
                '&:hover': {
                  transform: 'scale(1.05)',
                  animation: `${subtleHoverEffect} 1.5s infinite`,
                },
                border: '2px solid rgba(200, 200, 200, 0.5)',
                position: 'relative',
              }}
              onClick={() => handleImagePairClick(imagePair)}
            >
              <img 
                src={imagePair[1] || imagePair[0]} 
                alt={`Thumbnail ${startIndex + index}`} 
                style={{ width: '100%', height: '100%', objectFit: 'cover' }} 
              />
              {imagePair[1] && (
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    width: '25px', // 15% smaller
                    height: '25px', // 15% smaller
                    borderRadius: '8px', // Slightly reduced to maintain proportions
                    overflow: 'hidden',
                    border: '2px solid white',
                  }}
                >
                  <img 
                    src={imagePair[0]} 
                    alt={`Thumbnail ${startIndex + index} - 2`} 
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }} 
                  />
                </Box>
              )}
            </Box>
          ))
        )}
      </Box>

      <Button
        onClick={handleNext}
        disabled={startIndex + pairsPerPage >= allImagePairs.length}
        sx={{
          minWidth: 'auto',
          padding: '5px',
          position: 'absolute',
          [isMobile ? 'right' : 'bottom']: 5,
        }}
      >
        <ArrowForwardIosIcon fontSize="small" />
      </Button>

      <Modal
        open={selectedImagePair !== null}
        onClose={handleCloseModal}
        aria-labelledby="image-modal"
        aria-describedby="full-size-image-pair"
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            backdropFilter: 'blur(5px)',
          },
        }}
      >
        <Fade in={selectedImagePair !== null} timeout={fadeTransition}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
              borderRadius: '12px',
              p: 4,
              maxWidth: '95%',
              maxHeight: '95%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              overflow: 'hidden',
            }}
          >
            <IconButton
              aria-label="close"
              onClick={handleCloseModal}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            {selectedImagePair && (
              <>
                {loadingFullImage && (
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '75vh' }}>
                    <CircularProgress />
                  </Box>
                )}
                <Zoom in={!loadingFullImage} timeout={zoomTransition}>
                  <Box
                    ref={imageRef}
                    sx={{
                      overflow: 'hidden',
                      maxWidth: '85vw',
                      maxHeight: '75vh',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'relative',
                    }}
                    onWheel={(e) => {
                      const delta = e.deltaY * -0.01;
                      const newZoom = Math.min(Math.max(zoomLevel + delta, 1), 3);
                      
                      if (imageRef.current) {
                        const rect = imageRef.current.getBoundingClientRect();
                        const x = (e.clientX - rect.left) / imageRef.current.width;
                        const y = (e.clientY - rect.top) / imageRef.current.height;

                        imageRef.current.style.transformOrigin = `${x * 100}% ${y * 100}%`;
                      }

                      setZoomLevel(newZoom);
                    }}
                    style={{ touchAction: 'none' }}
                  >
                    <img 
                      src={showSecondImage && selectedImagePair[1] ? selectedImagePair[1] : selectedImagePair[0]} 
                      alt={`Full size ${showSecondImage ? '1' : '2'}`} 
                      style={{ 
                        maxWidth: '100%', 
                        maxHeight: '100%', 
                        width: 'auto', 
                        height: 'auto', 
                        transform: `scale(${zoomLevel})`,
                        transition: 'transform 0.3s ease-in-out',
                        cursor: 'zoom-in',
                      }} 
                      onLoad={handleImageLoad}
                    />
                    <Typography
                      sx={{
                        position: 'absolute',
                        bottom: 10,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        color: 'white',
                        padding: '5px 10px',
                        borderRadius: '15px',
                        opacity: 0.7,
                      }}
                    >
                      Scroll to zoom
                    </Typography>
                  </Box>
                </Zoom>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                  {selectedImagePair[1] && (
                    <StyledButton
                      onClick={handleToggleImage}
                      variant="outlined"
                      startIcon={<SwapHorizIcon />}
                    >
                      {showSecondImage ? "Show First Image" : "Show Second Image"}
                    </StyledButton>
                  )}
                  <StyledButton onClick={handleFullScreen} variant="outlined" startIcon={<FullscreenIcon />}>
                    {isFullScreen ? "Exit Fullscreen" : "Fullscreen"}
                  </StyledButton>
                </Box>
              </>
            )}
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default React.memo(ImageSidebar);
