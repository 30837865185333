import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import { Link } from '@mui/material';
import useSWR from 'swr';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Analytics } from "@vercel/analytics/react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { Helmet } from 'react-helmet-async';
import Auth from './components/Auth';
import ImageGenerator from './components/ImageGenerator';
import Gallery from './components/Gallery';
import EditProfile from './components/EditProfile';
import PublicGallery from './components/PublicGallery';
import BackgroundImages from './components/BackgroundImages';
import LoraModelsLibrary from './components/LoraModelsLibrary';
import Notifications from './components/Notifications';
import ChatComponent from './components/ChatTab';
import '@fontsource-variable/source-code-pro';
import { supabase, isAdmin } from './supabaseClient';
import { styled } from '@mui/system';
import { runComfyDeployWorkflow, CHAT_WORKFLOW_DEPLOYMENT_ID } from './api/comfyDeployApi';

 const DraggableNavbar = styled('div')(({ theme }) => ({
   overflowX: 'auto',
   whiteSpace: 'nowrap',
   cursor: 'grab',
   '&:active': {
     cursor: 'grabbing',
   },
   '&::-webkit-scrollbar': {
     display: 'none',
   },
   scrollbarWidth: 'thin',
   scrollbarColor: '#00ffff rgba(0, 255, 255, 0.1)',
   msOverflowStyle: 'none',
 }));

const ClosureNotice = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  borderRadius: theme.spacing(2),
  border: '1px solid rgba(255, 255, 255, 0.1)',
  textAlign: 'center',
  '& a': {
    color: '#00ffff',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#121212', // Changed back to dark gray
      paper: '#1E1E1E', // Changed back to dark gray
    },
  },
  typography: {
    fontFamily: 'Source Code Pro Variable, sans-serif',
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#000001', // Changed to a slightly lighter dark gray
          boxShadow: 'yes', // Remove the default shadow
        },
      },
    },
  },
});

const WarningBanner = ({ message, onClose, isAdmin, onEdit }) => (
  <Alert 
    severity="warning" 
    sx={{ width: '100%', mb: 2 }}
    onClose={onClose}
    action={
      <>
        {isAdmin && (
          <Button color="inherit" size="small" onClick={onEdit}>
            Edit
          </Button>
        )}
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={onClose}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </>
    }
  >
    {message}
  </Alert>
);


function App() {
  const imageGeneratorRef = useRef(null);
  const navbarRef = useRef(null);
  const [profile, setProfile] = useState(null);
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [editProfileOpen, setEditProfileOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [authOpen, setAuthOpen] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState('');
  const [showWarning, setShowWarning] = useState(false);
  const [showBackgroundImages, setShowBackgroundImages] = useState(false);
  const [warningMessage, setWarningMessage] = useState('');
  const [editWarningOpen, setEditWarningOpen] = useState(false);
  const [editWarningMessage, setEditWarningMessage] = useState('');

  const handleCloseWarning = useCallback(() => {
    setShowWarning(false);
    localStorage.setItem('warningClosedTimestamp', Date.now().toString());
  }, []);

  const handleEditWarning = useCallback(() => {
    setEditWarningMessage(warningMessage);
    setEditWarningOpen(true);
  }, [warningMessage]);

  const handleSaveWarning = useCallback(async (newMessage) => {
    try {
      const { error } = await supabase
        .from('app_settings')
        .update({ value: newMessage })
        .eq('key', 'warning_message');

      if (error) throw error;

      setWarningMessage(newMessage);
      setEditWarningOpen(false);
      setShowWarning(true);
      localStorage.removeItem('warningClosedTimestamp');
    } catch (error) {
      console.error('Error updating warning message:', error);
    }
  }, []);

  const memoizedWarningBanner = useMemo(() => (
    showWarning ? (
      <WarningBanner 
        message={warningMessage} 
        onClose={handleCloseWarning} 
        isAdmin={isUserAdmin}
        onEdit={handleEditWarning}
      />
    ) : null
  ), [showWarning, warningMessage, handleCloseWarning, isUserAdmin, handleEditWarning]);

  useEffect(() => {
    const fetchWarningMessage = async () => {
      const { data, error } = await supabase
        .from('app_settings')
        .select('value')
        .eq('key', 'warning_message')
        .single();

      if (error) {
        console.error('Error fetching warning message:', error);
      } else {
        setWarningMessage(data.value);
        
        const warningClosedTimestamp = localStorage.getItem('warningClosedTimestamp');
        if (!warningClosedTimestamp) {
          setShowWarning(true);
        }
      }
    };

    fetchWarningMessage();
  }, []);

  const getPageTitle = () => {
    switch (activeTab) {
      case 0:
        return "FluxForge - Ultimate AI Hub | Flux LoRA Directory | Flux Dev Schnell Gallery | ";
      case 1:
        return "FluxForge - LoRA Models Library";
      case 2:
        return "FluxForge - My Gallery";
      case 3:
        return "FluxForge - Public Gallery";
      default:
        return "FluxForge - Generate AI Images, experience PromptCrafter, get your SVGs or Transparent Images with latest Flux Model.";
    }
  };

  const getPageDescription = () => {
    switch (activeTab) {
      case 0:
        return "Create stunning AI-generated transparent images with FluxForge's powerful image generation tool, PromptCrafter.";
      case 1:
        return "Browse and explore a curated collection of LoRA models for AI image generation.";
      case 2:
        return "View and manage your personal gallery of Flux AI generated images on FluxForge.";
      case 3:
        return "Explore a diverse collection of AI-generated images created by the FluxForge community.";
      default:
        return "FluxForge: Create, share, get svg, transparent png, and explore Flux-AI generated images with our powerful image generation tool.";
    }
  };

  const fetcher = async (url) => {
    if (url === '/api/session') {
      const { data, error } = await supabase.auth.getSession();
      if (error) throw error;
      return data.session;
    }
    // Add other fetcher cases as needed
  };

  const { data: session, mutate: mutateSession } = useSWR('/api/session', fetcher);

  useSWR(session ? `/api/profile/${session.user.id}` : null, async () => {
    if (session) {
      await fetchProfile(session.user.id);
      const adminStatus = await isAdmin(session.user.id);
      setIsUserAdmin(adminStatus);
    }
  });

  React.useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange(async (event, session) => {
      mutateSession(session);
    });

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, [mutateSession]);

  const fetchProfile = async (userId) => {
    const { data, error } = await supabase
      .from('profiles')
      .select('*')
      .eq('id', userId)
      .single();

    if (error) {
      // console.error('Error fetching profile:', error);
    } else {
      setProfile(data);
      setAvatarUrl(data.avatar_url || '');
    }
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    await supabase.auth.signOut();
    setAnchorEl(null);
    setActiveTab(0);
  };

  const handleEditProfile = () => {
    setEditProfileOpen(true);
    handleClose();
  };

  const handleCloseEditProfile = () => {
    setEditProfileOpen(false);
  };

  const handleToggleBackgroundImages = () => {
    setShowBackgroundImages(!showBackgroundImages);
    handleClose();
  };

  const handleProfileUpdate = useCallback((newSession, newProfile) => {
    mutateSession(newSession);
    setProfile(newProfile);
    setAvatarUrl(newProfile.avatar_url || '');
  }, [mutateSession]);

  const [warningOpen, setWarningOpen] = useState(false);

  const [comingSoonWarningOpen, setComingSoonWarningOpen] = useState(false);

  const handleTabChange = (event, newValue) => {
    if (activeTab === 0 && document.querySelector('.MuiCircularProgress-root')) {
      setWarningOpen(true);
    } else if (newValue === 2 && !session) {
      setAuthOpen(true);
    } else {
      setActiveTab(newValue);
    }
  };

  const handleComingSoonWarningClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setComingSoonWarningOpen(false);
  };

  const handleWarningClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setWarningOpen(false);
  };

  const handleAuthClose = () => {
    setAuthOpen(false);
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Helmet>
        <title>{getPageTitle()}</title>
        <meta name="description" content={getPageDescription()} />
      </Helmet>
      <AppBar position="fixed">
        <Toolbar>
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
            <img src="/logo.png" alt="FluxForge Logo" style={{ height: '30px', marginRight: '10px' }} />
          </Box>
          {session ? (
            <>
              <Notifications session={session} />
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <Avatar alt={profile?.name || session.user.email} src={avatarUrl} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleEditProfile}>Edit Profile</MenuItem>
                <MenuItem onClick={handleToggleBackgroundImages}>
                  {showBackgroundImages ? 'Hide Background Images' : 'Show Background Images'}
                </MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </>
          ) : (
            <Button color="inherit" onClick={() => setAuthOpen(true)}>Login</Button>
          )}
        </Toolbar>
      </AppBar>
      <Container maxWidth="lg" sx={{ paddingTop: '64px', paddingBottom: '64px' }}> {/* Increased maxWidth and added bottom padding */}
        <BackgroundImages show={showBackgroundImages} />
        <Box sx={{ my: 4, display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}> {/* Changed alignItems to 'stretch' */}
           {memoizedWarningBanner}
           <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: 2 }}> {/* Added marginBottom */}
             <DraggableNavbar ref={navbarRef}>
               <Tabs
                 value={activeTab}
                 onChange={handleTabChange}
                 variant="scrollable"
                 scrollButtons="auto"
               >
                 <Tab label="Generate" />
                 <Tab label="LoRA Models" />
                 <Tab label="My Gallery" />
                 <Tab label="Public Gallery" />
                 {/* <Tab label="Model Training" /> */}
                 {/* <Tab label="3D Editor" /> */}
                 {/* <Tab label="Chat" /> */}
               </Tabs>
             </DraggableNavbar>
           </Box>
           <Box sx={{ width: '100%', maxWidth: '100%' }}> {/* Added a wrapper Box for content */}
             {activeTab === 0 && (
               <Box sx={{ maxWidth: 800, margin: '0 auto', padding: 3 }}>
                 <ClosureNotice>
                   <Typography variant="h4" gutterBottom sx={{ color: '#00ffff' }}>
                     Thank You for Being Part of Our Journey
                   </Typography>
                   <Typography variant="body1" paragraph>
                     It has been an incredible honor to serve our creative community. FluxForge is now closing its image generation service, but the memories and art we created together will live on.
                   </Typography>
                   <Typography variant="body1" paragraph>
                     Special thanks to ComfyDeploy, the amazing people behind open source diffusion models, and the wonderful Comfy.org community. 
                     Particular gratitude to @BennyKok and the entire ComfyDeploy team for their exceptional support.
                   </Typography>
                   <Typography variant="body1" paragraph>
                     While the image generator is no longer available, you can still browse our gallery of past creations.
                   </Typography>
                   <Typography variant="body1" paragraph>
                     Follow our creator for future projects:<br />
                     <Link href="https://instagram.com/ogkai" target="_blank" rel="noopener noreferrer">@ogkai on Instagram</Link><br />
                     <Link href="https://github.com/tercumantanumut" target="_blank" rel="noopener noreferrer">tercumantanumut on GitHub</Link>
                   </Typography>
                 </ClosureNotice>
               </Box>
             )}
             {activeTab === 1 && <LoraModelsLibrary />}
             {activeTab === 2 && (session ? <Gallery /> : <Auth open={true} onClose={() => setActiveTab(0)} />)}
             {activeTab === 3 && <PublicGallery session={session} isAdmin={isUserAdmin} />}
             {/* Model Training component is hidden
             {activeTab === 4 && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
               <Typography variant="h4">Model Training - Coming Soon</Typography>
             </Box>}
             */}
             {/* Chat component is hidden
             {activeTab === 5 && (
               session ? (
                 <ChatComponent 
                   containerStyle={{ height: 'calc(100vh - 200px)' }}
                   session={session}
                   onSendMessage={async (message) => {
                     try {
                       const response = await runComfyDeployWorkflow(CHAT_WORKFLOW_DEPLOYMENT_ID, { message });
                       return response[0]?.data?.message || 'No response from the server';
                     } catch (error) {
                       console.error('Error sending message:', error);
                       return 'Error: Unable to process your request';
                     }
                   }}
                 />
               ) : (
                 <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                   <Typography variant="h6">Please log in to use the chat feature.</Typography>
                 </Box>
               )
             )}
             */}
           </Box>
          {/* {activeTab === 5 && (
            <ThreeDEditor 
              onGenerateImage={(sceneData) => {
                setActiveTab(0); // Switch to the ImageGenerator tab
                setTimeout(() => {
                  if (imageGeneratorRef.current && imageGeneratorRef.current.generate3DScene) {
                    imageGeneratorRef.current.generate3DScene(sceneData);
                  } else {
                    console.error('ImageGenerator ref or generate3DScene method not available');
                  }
                }, 100);
              }} 
            />
          )} */}
          {authOpen && <Auth open={authOpen} onClose={handleAuthClose} />}
        </Box>
      </Container>
      {session && (
        <EditProfile
          open={editProfileOpen}
          onClose={handleCloseEditProfile}
          user={session.user}
          onUpdate={handleProfileUpdate}
        />
      )}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={warningOpen}
        autoHideDuration={6000}
        onClose={handleWarningClose}
        message="Cannot switch pages while generating an image"
      />
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={comingSoonWarningOpen}
        autoHideDuration={6000}
        onClose={handleComingSoonWarningClose}
        message="Coming Soon"
      />
      {authOpen && (
        <Auth 
          open={authOpen} 
          onClose={handleAuthClose} 
          onLoginSuccess={(message) => {
            // Don't close the Auth dialog immediately
            // Instead, let the Auth component handle the closing
            console.log(message); // Log the success message
            setActiveTab(0); // Switch to Generate tab after successful login
          }}
          onError={(error) => {
            console.error('Authentication error:', error);
            const errorMessage = error.message || 'An error occurred during authentication';
            setWarningOpen(true);
            setWarningMessage(errorMessage);
          }}
        />
      )}
      <Box component="footer" sx={{ 
        backgroundColor: '#000000', 
        color: '#ffffff', 
        padding: '1rem', 
        textAlign: 'center',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0
      }}>
        © 2024 Flux Forge. All rights reserved. 
        <span id="who-are-we" style={{ marginLeft: '1rem', cursor: 'pointer' }} onClick={() => {
          const dialog = document.getElementById('who-are-we-dialog');
          if (dialog) {
            dialog.style.display = 'block';
            dialog.style.opacity = '0';
            setTimeout(() => {
              dialog.style.transition = 'opacity 0.5s ease-in-out';
              dialog.style.opacity = '1';
              const typedText = document.getElementById('typed-text');
              if (typedText && window.animateText) {
                window.animateText();
              }
            }, 10);
          }
        }}></span>
      </Box>
      <EditWarningDialog
         open={editWarningOpen}
         onClose={() => setEditWarningOpen(false)}
         onSave={handleSaveWarning}
         initialMessage={editWarningMessage}
       />
       <Analytics />
     </ThemeProvider>
  );
}

const EditWarningDialog = ({ open, onClose, onSave, initialMessage }) => {
  const [message, setMessage] = useState(initialMessage);

  const handleSave = () => {
    onSave(message);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Warning Message</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="message"
          label="Warning Message"
          type="text"
          fullWidth
          multiline
          rows={4}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default App;
