import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, Modal, Avatar } from '@mui/material';
import { supabase } from '../supabaseClient';
import { generateRandomName } from '../utils/nameGenerator';

const EditProfile = ({ open, onClose, user, onUpdate }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [avatar, setAvatar] = useState(null);
  const [avatarUrl, setAvatarUrl] = useState('');

  useEffect(() => {
    if (user) {
      fetchProfile(user.id);
      setEmail(user.email || '');
    }
  }, [user]);

  const fetchProfile = async (userId) => {
    const { data, error } = await supabase
      .from('profiles')
      .select('name, avatar_url')
      .eq('id', userId)
      .single();

    if (error) {
      console.error('Error fetching profile:', error);
    } else {
      if (data && data.name) {
        setName(data.name);
        // console.log('Fetched name:', data.name);
      } else {
        const randomName = generateRandomName();
        setName(randomName);
        // console.log('Generated random name:', randomName);
        
        // Save the generated name to the database
        const { error: updateError } = await supabase
          .from('profiles')
          .upsert({ id: userId, name: randomName }, { onConflict: 'id' });
        
        if (updateError) {
          // console.error('Error updating profile with random name:', updateError);
        } else {
          // console.log('Updated profile with random name:', randomName);
        }
      }
      setAvatarUrl(data?.avatar_url || '');
    }
  };

  useEffect(() => {
    if (user) {
      fetchProfile(user.id);
    }
  }, [user]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 50 * 1024 * 1024) { // 50MB limit
        alert('File is too large. Please choose an image under 50MB.');
        return;
      }
      if (!file.type.startsWith('image/')) {
        alert('Please select an image file.');
        return;
      }
      setAvatar(file);
    }
  };

  const uploadAvatar = async () => {
    if (!avatar) return null;

    const fileExt = avatar.name.split('.').pop();
    const fileName = `${Math.random()}.${fileExt}`;
    const filePath = `${user.id}/${fileName}`;

    try {
      // // console.log('Starting avatar upload...');
      // // console.log('File path:', filePath);
      const { data, error } = await supabase.storage
        .from('avatars')
        .upload(filePath, avatar, {
          cacheControl: '3600',
          upsert: false
        });

      if (error) {
        // // console.error('Supabase storage upload error:', error);
        throw error;
      }

      // // console.log('Avatar uploaded successfully, getting public URL...');
      const { data: { publicUrl }, error: urlError } = supabase.storage
        .from('avatars')
        .getPublicUrl(filePath);

      if (urlError) {
        // // console.error('Error getting public URL:', urlError);
        throw urlError;
      }

      // // console.log('Public URL obtained:', publicUrl);
      // // console.log('New avatar URL:', publicUrl);
      return publicUrl;
    } catch (error) {
      // console.error('Detailed error in avatar upload:', error);
      if (error.message.includes('Payload too large')) {
        alert('The image file is too large. Please try a smaller image.');
      } else if (error.message.includes('new row violates row-level security policy')) {
        alert('Permission denied. Please ensure you are logged in and try again.');
      } else {
        alert(`Error uploading avatar: ${error.message}`);
      }
      return null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let newAvatarUrl = avatarUrl;
      if (avatar) {
        newAvatarUrl = await uploadAvatar();
        if (!newAvatarUrl) {
          return; // Exit if avatar upload failed
        }
      }

      let updateData = {
        data: { name: name, avatar_url: newAvatarUrl }
      };

      // Only update email if it has changed
      if (email !== user.email) {
        updateData.email = email;
      }

      // Update user email if changed
      if (email !== user.email) {
        const { error: emailError } = await supabase.auth.updateUser({ email: email });
        if (emailError) throw emailError;
      }

      // Update the profile in the public profiles table
      const { data: profileData, error: profileError } = await supabase
        .from('profiles')
        .upsert({ 
          id: user.id, 
          name: name, 
          avatar_url: newAvatarUrl,
          updated_at: new Date()
        })
        .select()
        .single();

      if (profileError) throw profileError;

      // Fetch the updated session
      const { data: { session }, error: sessionError } = await supabase.auth.getSession();
      if (sessionError) throw sessionError;

      // Call the onUpdate prop with the new session and profile
      onUpdate(session, profileData);

      // Update local state
      setAvatarUrl(newAvatarUrl);
      // console.log('Updated avatar URL:', newAvatarUrl);

      alert('Profile updated successfully!');
      onClose();
    } catch (error) {
      // // console.error('Error updating profile:', error);
      alert(`Error updating the profile: ${error.message || 'Unknown error occurred'}`);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
      }}>
        <Typography variant="h6" component="h2" gutterBottom>
          Edit Profile
        </Typography>
        <form onSubmit={handleSubmit}>
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
            <Avatar
              src={avatar ? URL.createObjectURL(avatar) : avatarUrl}
              sx={{ width: 100, height: 100 }}
            />
          </Box>
          <Button
            variant="contained"
            component="label"
            fullWidth
            sx={{ mb: 2 }}
          >
            Upload Avatar
            <input
              type="file"
              hidden
              accept="image/*"
              onChange={handleFileChange}
            />
          </Button>
          <TextField
            fullWidth
            margin="normal"
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
          >
            Save Changes
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default EditProfile;
