import { supabase } from '../supabaseClient';

export const sendNotification = async (userId, notification) => {
  try {
    const { data, error } = await supabase
      .from('notifications')
      .insert([
        { 
          user_id: userId, 
          type: notification.type,
          message: notification.message,
          image_id: notification.image_id,
          read: false
        }
      ]);

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error sending notification:', error);
    throw error;
  }
};

export const fetchNotifications = async (userId) => {
  try {
    const { data, error } = await supabase
      .from('notifications')
      .select('*')
      .eq('user_id', userId)
      .order('created_at', { ascending: false });

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error fetching notifications:', error);
    return [];
  }
};

export const markNotificationAsRead = async (notificationId) => {
  try {
    const { data, error } = await supabase
      .from('notifications')
      .update({ read: true })
      .eq('id', notificationId);

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error marking notification as read:', error);
  }
};

export const fetchUserNames = async (userIds) => {
  try {
    const { data, error } = await supabase
      .from('profiles')
      .select('id, name')
      .in('id', userIds);

    if (error) throw error;
    return Object.fromEntries(data.map(p => [p.id, p.name]));
  } catch (error) {
    console.error('Error fetching user names:', error);
    return {};
  }
};
