import { supabase } from '../supabaseClient';
import { v4 as uuidv4 } from 'uuid';

class UsageLimiter {
  constructor() {
    this.MAX_CREDITS_PER_DAY = 20;
  }

  getOrCreateAnonymousId() {
    let anonymousId = localStorage.getItem('anonymousId');
    if (!anonymousId) {
      anonymousId = uuidv4();
      localStorage.setItem('anonymousId', anonymousId);
    }
    return anonymousId;
  }

  async checkLimit(userIdOrSessionId) {
    if (!userIdOrSessionId) {
      userIdOrSessionId = this.getOrCreateAnonymousId();
    }

    // If it's a registered user (not an anonymous ID), always return true
    if (userIdOrSessionId !== this.getOrCreateAnonymousId()) {
      return true;
    }

    const today = new Date().toISOString().split('T')[0]; // Format: YYYY-MM-DD

    try {
      const { data, error } = await supabase
        .from('user_credits')
        .select('credits_used')
        .eq('user_or_session_id', userIdOrSessionId)
        .eq('date', today)
        .single();

      if (error) {
        if (error.code === 'PGRST116') {
          // No data found, initialize credits for the day
          await supabase
            .from('user_credits')
            .insert({ user_id: userIdOrSessionId, user_or_session_id: userIdOrSessionId, date: today, credits_used: 0 });
          return true; // User can generate as no credits used yet
        }
        throw error;
      }

      return data.credits_used < this.MAX_CREDITS_PER_DAY;
    } catch (error) {
      return false;
    }
  }

  async incrementUsage(userIdOrSessionId) {
    if (!userIdOrSessionId) {
      userIdOrSessionId = this.getOrCreateAnonymousId();
    }

    // If it's a registered user (not an anonymous ID), always return true without incrementing
    if (userIdOrSessionId !== this.getOrCreateAnonymousId()) {
      return true;
    }

    const today = new Date().toISOString().split('T')[0]; // Format: YYYY-MM-DD

    try {
      // First, check if the anonymous user has already reached the limit
      const canGenerate = await this.checkLimit(userIdOrSessionId);
      if (!canGenerate) {
        return false;
      }

      // If the anonymous user hasn't reached the limit, proceed with incrementing
      const { data: selectData, error: selectError } = await supabase
        .from('user_credits')
        .select('credits_used')
        .eq('user_or_session_id', userIdOrSessionId)
        .eq('date', today)
        .single();

      if (selectError) {
        if (selectError.code === 'PGRST116') {
          // No data found, insert a new record
          const { error: insertError } = await supabase
            .from('user_credits')
            .insert({ 
              user_or_session_id: userIdOrSessionId, 
              date: today, 
              credits_used: 1 
            });

          if (insertError) throw insertError;
        } else {
          throw selectError;
        }
      } else {
        // Update existing record
        const updatedCredits = (selectData.credits_used || 0) + 1;
        const { error: updateError } = await supabase
          .from('user_credits')
          .update({ credits_used: updatedCredits })
          .eq('user_or_session_id', userIdOrSessionId)
          .eq('date', today);

        if (updateError) throw updateError;
      }

      return true;
    } catch (err) {
      throw err; // Re-throw the error to be handled by the caller
    }
  }
}

export default new UsageLimiter();
