import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Grid, Card, CardContent, CardMedia, Typography, Button, TextField, Select, MenuItem, FormControl, InputLabel, Box, CircularProgress, Modal, useMediaQuery, useTheme, Switch, FormControlLabel, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import localLoraModels from '../../src/lora_models.json';

// Subcomponents
const SearchBar = ({ onSearch }) => {
  const [localSearchTerm, setLocalSearchTerm] = useState('');
  const theme = useTheme();
  
  const debouncedSearch = useCallback(
    debounce((term) => {
      onSearch(term);
    }, 500),
    [onSearch]
  );

  const handleChange = (event) => {
    const newTerm = event.target.value;
    setLocalSearchTerm(newTerm);
    debouncedSearch(newTerm);
  };

  return (
    <TextField
      fullWidth
      variant="outlined"
      placeholder="Search LoRA models..."
      value={localSearchTerm}
      onChange={handleChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon color="action" />
          </InputAdornment>
        ),
      }}
      sx={{
        '& .MuiOutlinedInput-root': {
          borderRadius: '20px',
          backgroundColor: theme.palette.background.default,
        },
      }}
    />
  );
};

const SortingDropdown = ({ sortBy, onSort }) => {
  const theme = useTheme();
  return (
    <FormControl variant="outlined" sx={{ minWidth: 120 }}>
      <Select
        value={sortBy}
        onChange={onSort}
        displayEmpty
        sx={{
          borderRadius: '20px',
          backgroundColor: theme.palette.background.default,
          '& .MuiSelect-select': {
            paddingY: '10px',
          },
        }}
      >
        <MenuItem value="" disabled>Sort By</MenuItem>
        <MenuItem value="likes">Likes</MenuItem>
        <MenuItem value="downloads">Downloads</MenuItem>
        <MenuItem value="date">Date</MenuItem>
        <MenuItem value="name">Name</MenuItem>
      </Select>
    </FormControl>
  );
};

const MediaDisplay = ({ model, onMediaClick }) => {
  const [isVideo, setIsVideo] = useState(false);

  useEffect(() => {
    const checkIfVideo = async () => {
      try {
        const response = await fetch(model.image_url, { method: 'HEAD' });
        const contentType = response.headers.get('content-type');
        setIsVideo(contentType.startsWith('video/'));
      } catch (error) {
        // Error checking media type
        setIsVideo(false);
      }
    };

    checkIfVideo();
  }, [model.image_url]);

  if (isVideo) {
    return (
      <video
        height="140"
        src={model.image_url}
        onClick={() => onMediaClick(model)}
        style={{ cursor: 'pointer', objectFit: 'cover', width: '100%' }}
        muted
        loop
        autoPlay
      />
    );
  }

  return (
    <img
      height="140"
      src={model.image_url || 'https://via.placeholder.com/140'}
      alt={model.name}
      onClick={() => onMediaClick(model)}
      style={{ cursor: 'pointer', objectFit: 'cover', width: '100%' }}
    />
  );
};

const ModelCard = ({ model, onMediaClick }) => (
  <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
    <MediaDisplay model={model} onMediaClick={onMediaClick} />
    <CardContent sx={{ flexGrow: 1 }}>
      <Typography gutterBottom variant="h6" component="div">
        {model.name}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Likes: {model.likes}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Downloads: {model.downloads}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Date: {model.date}
      </Typography>
    </CardContent>
    <Button 
      size="small" 
      color="primary" 
      href={model.link} 
      target="_blank" 
      rel="noopener noreferrer"
    >
      View on {model.link.includes('huggingface.co') ? 'Hugging Face' : 'Civitai'}
    </Button>
  </Card>
);

const MediaModal = ({ open, onClose, model }) => {
  const [isVideo, setIsVideo] = useState(false);

  useEffect(() => {
    if (model) {
      const checkIfVideo = async () => {
        try {
          const response = await fetch(model.image_url, { method: 'HEAD' });
          const contentType = response.headers.get('content-type');
          setIsVideo(contentType.startsWith('video/'));
        } catch (error) {
          // Error checking media type
          setIsVideo(false);
        }
      };

      checkIfVideo();
    }
  }, [model]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        maxWidth: 600,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
      }}>
        {model && (
          <>
            {isVideo ? (
              <video
                src={model.image_url}
                style={{ width: '100%', height: 'auto' }}
                controls
                autoPlay
                loop
              />
            ) : (
              <img src={model.image_url} alt={model.name} style={{ width: '100%', height: 'auto' }} />
            )}
            <Typography variant="h6" component="h2" mt={2}>
              {model.name}
            </Typography>
            <Typography sx={{ mt: 2 }}>
              Likes: {model.likes} | Downloads: {model.downloads}
            </Typography>
          </>
        )}
      </Box>
    </Modal>
  );
};

const LoraModelsLibrary = () => {
  const [models, setModels] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('likes');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedModel, setSelectedModel] = useState(null);
  const [hideNSFW, setHideNSFW] = useState(true);
  const observer = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const nsfwKeywords = [
    'nsfw', 'adult', 'xxx', 'breasts', 'porn', 'sex', 'nude', 'naked', 'explicit',
    'erotic', 'hentai', 'boobs', 'ass', 'pussy', 'dick', 'cock', 'penis',
    'vagina', 'fetish', 'bdsm', 'kinky', 'mature', '18+', 'r18', 'lewd',
    'ecchi', 'ahegao', 'lingerie', 'topless',
    'bottomless', 'nipples', 'genitals', 'crotch', 'panties', 'underwear',
    'thong', 'bondage', 'dominatrix', 'submissive', 'orgasm', 'climax',
    'cum', 'facial', 'bukkake', 'gangbang', 'orgy', 'threesome', 'foursome',
    'blowjob', 'handjob', 'footjob', 'titjob', 'anal', 'penetration',
    'dildo', 'vibrator', 'fleshlight', 'onahole', 'masturbation', 'fingering',
    'futa', 'futanari', 'trap', 'yaoi', 'yuri', 'bara', 'tentacle',
    'exhibitionist', 'voyeur', 'upskirt', 'cameltoe', 'milf', 'gilf',
    'creampie', 'squirt', 'ahegao', 'mindbreak', 'corruption', 'defloration',
    'virginity', 'rape', 'noncon', 'blackmail', 'prostitution', 'incest',
    'lolicon', 'shotacon', 'guro', 'vore', 'scat', 'watersports', 'gore',
    'ryona', 'snuff', 'necrophilia', 'zoophilia', 'bestiality'
  ];

  const fetchModels = useCallback(async () => {
    try {
      setLoading(true);
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 9000); // 9-second timeout

      const response = await fetch(`https://fluxforge.app/api/lora-models?page=${page}&limit=20&search=${searchTerm}&sortBy=${sortBy}&hideNSFW=${hideNSFW}`, {
        signal: controller.signal
      });

      clearTimeout(timeoutId);

      if (!response.ok) throw new Error('Failed to fetch models');
      const data = await response.json();
      setModels(prevModels => page === 1 ? data.models : [...prevModels, ...data.models]);
      setHasMore(data.hasMore);
      setError(null);
    } catch (error) {
      // Error fetching LoRA models
      // Fallback to local JSON file
      const filteredModels = localLoraModels.filter(model => 
        model.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
        (!hideNSFW || !nsfwKeywords.some(keyword => model.name.toLowerCase().includes(keyword)))
      );
      const sortedModels = filteredModels.sort((a, b) => b[sortBy] - a[sortBy]);
      const paginatedModels = sortedModels.slice(0, page * 20);
      setModels(paginatedModels);
      setHasMore(paginatedModels.length < filteredModels.length);
      setError('Using local data. Live data unavailable.');
    } finally {
      setLoading(false);
    }
  }, [page, searchTerm, sortBy, hideNSFW]);

  useEffect(() => {
    fetchModels();
  }, [fetchModels]);

  const handleSearch = useCallback((term) => {
    setSearchTerm(term);
    setPage(1);
    setModels([]);
    setHasMore(true);
  }, []);

  const handleSort = (event) => {
    setSortBy(event.target.value);
    setPage(1);
    setModels([]);
    setHasMore(true);
  };

  const handleNSFWToggle = (checked) => {
    setHideNSFW(checked);
    setPage(1);
    setModels([]);
    setHasMore(true);
  };

  const handleMediaClick = (model) => {
    setSelectedModel(model);
    setModalOpen(true);
  };

  const lastModelElementRef = useCallback(node => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPage(prevPage => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [loading, hasMore]);

  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        LoRA Models Library
      </Typography>
      <Box sx={{ 
        display: 'flex', 
        flexDirection: isMobile ? 'column' : 'row', 
        alignItems: 'center',
        backgroundColor: theme.palette.background.paper,
        borderRadius: 2,
        padding: 2,
        marginBottom: 3,
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
      }}>
        <Box sx={{ 
          flex: 1,
          marginRight: isMobile ? 0 : 2,
          marginBottom: isMobile ? 2 : 0
        }}>
          <SearchBar onSearch={handleSearch} />
        </Box>
        <Box sx={{ 
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: isMobile ? 'stretch' : 'center',
          gap: 2,
          width: isMobile ? '100%' : 'auto'
        }}>
          <SortingDropdown sortBy={sortBy} onSort={handleSort} />
          <FormControlLabel
            control={
              <Switch
                checked={hideNSFW}
                onChange={(e) => handleNSFWToggle(e.target.checked)}
                color="primary"
              />
            }
            label="Hide NSFW"
            sx={{
              margin: 0,
              '& .MuiFormControlLabel-label': {
                fontSize: '0.875rem',
                marginLeft: 1
              }
            }}
          />
        </Box>
      </Box>
      {error && (
        <Typography color="error" sx={{ marginBottom: 2 }}>
          {error}
        </Typography>
      )}
      <Grid container spacing={3}>
        {models.map((model, index) => (
          <Grid item xs={12} sm={6} md={4} key={model.id} ref={index === models.length - 1 ? lastModelElementRef : null}>
            <ModelCard model={model} onMediaClick={handleMediaClick} />
          </Grid>
        ))}
      </Grid>
      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
          <CircularProgress />
        </Box>
      )}
      <MediaModal open={modalOpen} onClose={() => setModalOpen(false)} model={selectedModel} />
    </Box>
  );
};

// PropTypes remain the same

export default LoraModelsLibrary;
