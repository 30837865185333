import React, { useState, useEffect } from 'react';
import { Badge, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { fetchNotifications, markNotificationAsRead, fetchUserNames } from '../utils/notifications';

const Notifications = ({ session }) => {
  const [notifications, setNotifications] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (session) {
      loadNotifications();
    }
  }, [session]);

  const loadNotifications = async () => {
    try {
      const fetchedNotifications = await fetchNotifications(session.user.id);
      
      // Fetch names for all unique user IDs in the notifications
      const userIds = [...new Set(fetchedNotifications.map(n => n.user_id))];
      const userNames = await fetchUserNames(userIds);
      
      // Replace user_id with name in notifications
      const notificationsWithNames = fetchedNotifications.map(n => ({
        ...n,
        user_name: userNames[n.user_id] || 'Unknown User'
      }));

      setNotifications(notificationsWithNames);
    } catch (error) {
      console.error('Error loading notifications:', error);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationClick = async (notification) => {
    try {
      await markNotificationAsRead(notification.id);
      await loadNotifications();
      handleClose();
      // You can add navigation logic here if needed
    } catch (error) {
      console.error('Error handling notification click:', error);
    }
  };

  const unreadCount = notifications.filter(n => !n.read).length;

  return (
    <>
      <IconButton color="inherit" onClick={handleClick}>
        <Badge badgeContent={unreadCount} color="secondary">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {notifications.length === 0 ? (
          <MenuItem>No notifications</MenuItem>
        ) : (
          notifications.map((notification) => (
            <MenuItem
              key={notification.id}
              onClick={() => handleNotificationClick(notification)}
              sx={{ backgroundColor: notification.read ? 'inherit' : 'action.hover' }}
            >
              <Typography variant="body2">
                {notification.message}
              </Typography>
            </MenuItem>
          ))
        )}
      </Menu>
    </>
  );
};

export default Notifications;
