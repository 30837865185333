import React, { useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { keyframes, styled } from '@mui/material/styles';

// Keyframes for straight vertical movement
const floatAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100vh);
  }
`;

// Alternatively, if you want diagonal movement, use this keyframe
/*
const floatAnimation = keyframes`
  0% {
    transform: translateY(0) translateX(0);
  }
  100% {
    transform: translateY(-100vh) translateX(50px);
  }
`;
*/

const ParticleBackground = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  pointerEvents: 'none',
}));

const Particle = styled('div')(({ theme, size, duration, delay }) => ({
  position: 'absolute',
  width: `${size}px`,
  height: `${size}px`,
  background: 'rgba(255, 255, 255, 0.08)',
  borderRadius: '50%',
  animation: `${floatAnimation} ${duration}s linear infinite`,
  animationDelay: `${delay}s`,
}));

const Particles = ({ count = 150 }) => {
  const particlesRef = useRef([]);

  useEffect(() => {
    particlesRef.current = Array(count).fill().map(() => ({
      size: Math.random() * 3 + 1,
      duration: Math.random() * 20 + 10,
      delay: Math.random() * 10,
      top: `${Math.random() * 100 + 100}%`,
      left: `${Math.random() * 100}%`,
    }));
  }, [count]);

  return (
    <ParticleBackground>
      {particlesRef.current.map((particle, index) => (
        <Particle
          key={index}
          size={particle.size}
          duration={particle.duration}
          delay={particle.delay}
          style={{ top: particle.top, left: particle.left }}
        />
      ))}
    </ParticleBackground>
  );
};

export default Particles;
