import React, { useState, useRef, useCallback, useEffect } from 'react';
import useSWR from 'swr';
import { Button, TextField, Typography, Box, Slider, CircularProgress, LinearProgress, InputAdornment, Menu, MenuItem, Modal, Snackbar, useMediaQuery, Fade, Checkbox, FormControlLabel, Switch, Tooltip, IconButton } from '@mui/material';
import { useTheme, keyframes, styled, alpha } from '@mui/material/styles';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PsychologyIcon from '@mui/icons-material/Psychology';
import BlockIcon from '@mui/icons-material/Block';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ChatIcon from '@mui/icons-material/Chat';
import EditIcon from '@mui/icons-material/Edit';
import { useInterval } from '../hooks/useInterval';
import { Helmet } from 'react-helmet';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ImageSidebar from './ImageSidebar';
import CharacterCustomizer from './CharacterCustomizer';
import OutfitBuilder from './OutfitBuilder';
import BackgroundCustomizer from './BackgroundCustomizer';
import DialogueBalloonCustomizer from './DialogueBalloonCustomizer';
import ArtistStyleCustomizer from './ArtistStyleCustomizer';
import BackgroundImages from './BackgroundImages';
import ImaginationVault from './ImaginationVault';
import DimensionSelector from './DimensionSelector';
import { supabase } from '../supabaseClient';
import { runComfyDeployWorkflow } from '../api/comfyDeployApi';
import promptAnalyzer from '../utils/promptAnalyzer';
import ChatComponent from './ChatTab';
import UsageLimiter from '../utils/UsageLimiter';

const initialImageDimensions = { width: 1024, height: 1024, upscaleBy: 1 };

const pulseAnimation = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
`;

const rotateAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const EpicSwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
    width: 32,
    height: 32,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#fff',
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
    borderRadius: 20 / 2,
  },
}));

const SwitchablePromptBox = ({ prompt, setPrompt, handleRandomize, imageDimensions, setImageDimensions, handleMenuClick, isChatMode, setIsChatMode, onGenerateImage, handleGenerate, chatMessages, setChatMessages, isLoading, progress, loadingMessage, session }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isAnimating, setIsAnimating] = useState(false);
  const [showTooltip, setShowTooltip] = useState(true);
  const [showWarning, setShowWarning] = useState(false);

  const handleSwitchChange = () => {
    if (!session) {
      setShowWarning(true);
      return;
    }
    setIsAnimating(true);
    setTimeout(() => {
      setIsChatMode(!isChatMode);
      setIsAnimating(false);
      setShowTooltip(true);
      setTimeout(() => setShowTooltip(false), 5000); // Hide tooltip after 5 seconds
    }, 500);
  };

  const handleWarningClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowWarning(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => setShowTooltip(false), 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Box sx={{ position: 'relative', width: '100%', marginTop: '60px' }}>
      <Box
        sx={{
          position: 'absolute',
          top: '-50px',
          right: '0',
          display: 'flex',
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          borderRadius: '20px',
          padding: '5px 10px',
          boxShadow: '0 0 10px rgba(255, 255, 255, 0.3)',
          transition: 'all 0.3s ease',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            boxShadow: '0 0 15px rgba(255, 255, 255, 0.5)',
          },
        }}
      >
        <Tooltip
          title={
            <React.Fragment>
              <Typography color="inherit">Try our new Chat Mode!</Typography>
              <Typography variant="body2">
                Experience the future of AI image generation with our interactive chat interface. 
                Switch to Chat Mode for a more intuitive and conversational approach to creating stunning visuals.
              </Typography>
            </React.Fragment>
          }
          placement="left"
          open={showTooltip}
          sx={{
            '& .MuiTooltip-tooltip': {
              backgroundColor: alpha(theme.palette.background.paper, 0.9),
              color: theme.palette.text.primary,
              maxWidth: 300,
              fontSize: theme.typography.pxToRem(12),
              border: '1px solid #dadde9',
            },
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body2" sx={{ marginRight: '10px', color: 'white' }}>
              {isChatMode ? 'Chat' : 'Image'}
            </Typography>
            <EpicSwitch
              checked={isChatMode}
              onChange={handleSwitchChange}
              sx={{
                animation: isAnimating ? `${pulseAnimation} 0.5s ease-in-out` : 'none',
              }}
            />
          </Box>
        </Tooltip>
      </Box>
      <Box
        sx={{
          transition: 'all 0.5s ease',
          transform: isAnimating ? 'scale(0.95) rotate(2deg)' : 'scale(1) rotate(0deg)',
          opacity: isAnimating ? 0.7 : 1,
        }}
      >
        {isChatMode ? (
          <ChatComponent
            containerStyle={{
              height: '70vh',
              minHeight: '500px',
              width: '100%',
              marginBottom: '16px',
              borderRadius: '15px',
              boxShadow: '0 0 20px rgba(255, 255, 255, 0.1)',
              overflow: 'hidden',
            }}
            onGenerateImage={async (comfyParams) => {
              setPrompt(comfyParams.positive);
              const updatedParams = {
                ...comfyParams,
                input_memory: comfyParams.input_memory || ''
              };
              return handleGenerate(null, updatedParams);
            }}
            messages={chatMessages}
            setMessages={setChatMessages}
            isLoading={isLoading}
            progress={progress}
            loadingMessage={loadingMessage}
            session={session}
            imageDimensions={imageDimensions}
          />
        ) : (
          <TextField
            label="Prompt"
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            fullWidth
            margin="normal"
            multiline
            minRows={1}
            maxRows={isMobile ? 3 : 5}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    onClick={handleRandomize}
                    sx={{ minWidth: 'auto', backgroundColor: 'transparent', marginLeft: '8px' }}
                  >
                    <ShuffleIcon />
                  </Button>
                  <DimensionSelector
                    width={imageDimensions.width}
                    height={imageDimensions.height}
                    upscaleBy={imageDimensions.upscaleBy}
                    onChange={setImageDimensions}
                  />
                  <Button
                    onClick={handleMenuClick}
                    sx={{ minWidth: 'auto', backgroundColor: 'transparent', marginLeft: '8px' }}
                  >
                    <AddIcon />
                  </Button>
                </InputAdornment>
              ),
            }}
            sx={{
              width: '100%',
              marginBottom: '16px',
              '& .MuiInputBase-root': {
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                borderRadius: '15px',
                transition: 'all 0.3s ease',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.8)',
                },
              },
              '& .MuiInputBase-input': {
                color: 'white',
              },
              '& .MuiInputLabel-root': {
                color: 'rgba(255, 255, 255, 0.7)',
              },
            }}
          />
        )}
      </Box>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={showWarning}
        autoHideDuration={6000}
        onClose={() => setShowWarning(false)}
        message="Please sign up or sign in to access Chat Mode"
        sx={{
          bottom: { xs: '80px', sm: '100px' },
          zIndex: (theme) => theme.zIndex.drawer + 2,
        }}
      />
    </Box>
  );
};


const blinkAnimation = keyframes`
  0% { opacity: 0.7; }
  50% { opacity: 0.3; }
  100% { opacity: 0.7; }
`;

const ZoomModal = styled(Modal)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ZoomImage = styled('img')({
  maxHeight: '90vh',
  maxWidth: '90vw',
  objectFit: 'contain',
});

export default function ImageGenerator({ session }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [loading, setLoading] = useState(false);
  const [inputImage, setInputImage] = useState(null);
  const canvasRef = useRef(null);
  const [prompt, setPrompt] = useState('');
  const [insanityLevel, setInsanityLevel] = useState(5);
  const [nsfwFilter, setNsfwFilter] = useState(true);
  const [nsfwSensitivity, setNsfwSensitivity] = useState(0.5);
  const [batchSize] = useState(1);
  const [generatedImages, setGeneratedImages] = useState([]);
  const [fakeProgress, setFakeProgress] = useState(0);
  const [imagePairs, setImagePairs] = useState([]);
  const [mostRecentGeneration, setMostRecentGeneration] = useState([]);
  const [zoomedImage, setZoomedImage] = useState(null);
  const [imageDimensions, setImageDimensions] = useState(initialImageDimensions);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [allGeneratedImages, setAllGeneratedImages] = useState([]);
  const [isChatMode, setIsChatMode] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);
  const [uploadedImageUrl, setUploadedImageUrl] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);

  const loadingMessages = [
    "Service has been discontinued.",
    "Thank you for being part of our journey.",
    "The gallery remains available to browse past creations.",
    "Visit @ogkai on Instagram for future updates.",
  ];

  useInterval(() => {
    if (loading) {
      setLoadingMessage(loadingMessages[Math.floor(Math.random() * loadingMessages.length)]);
    }
  }, 5000);

  const handleCloseZoom = () => {
    setZoomedImage(null);
  };

  const fetchUserImages = useCallback(async () => {
    try {
      const { data: { session }, error: sessionError } = await supabase.auth.getSession();
      if (sessionError) {
        console.error('Error getting session:', sessionError);
        return;
      }
      
      if (session) {
        const { data, error } = await supabase
          .from('images')
          .select('url')
          .eq('user_id', session.user.id)
          .order('created_at', { ascending: false })
          .limit(20); // Limit to 20 most recent images

        if (error) throw error;
      
        const newImagePairs = data.reduce((acc, image, index, array) => {
          if (index % 2 === 0) {
            acc.push([image.url, array[index + 1] ? array[index + 1].url : null]);
          }
          return acc;
        }, []);
      
        setImagePairs(prevPairs => {
          if (JSON.stringify(prevPairs) !== JSON.stringify(newImagePairs)) {
            return newImagePairs;
          }
          return prevPairs;
        });
        setAllGeneratedImages(prevImages => {
          const newImages = data.map(item => item.url);
          if (JSON.stringify(prevImages) !== JSON.stringify(newImages)) {
            return newImages;
          }
          return prevImages;
        });
      } else {
        setImagePairs([]);
        setAllGeneratedImages([]);
      }
    } catch (error) {
      console.error('Error fetching user images:', error);
    }
  }, []);


  // Add this new effect to fetch images when the component mounts or when the user logs in
  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'SIGNED_IN') {
        fetchUserImages();
      }
    });

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, [fetchUserImages]);

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebApplication",
    "name": "FluxForge Image Generator",
    "description": "An AI-powered image generation tool that creates unique images based on user prompts.",
    "applicationCategory": "Multimedia",
    "operatingSystem": "Any",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD"
    },
    "featureList": [
      "AI-powered image generation",
      "Customizable prompts",
      "Adjustable creativity levels",
      "Character and outfit customization",
      "Background and style options"
    ]
  };

  // New state variables for the dropdown menu and customization fields
  const [anchorEl, setAnchorEl] = useState(null);
  const [characterCustomizerOpen, setCharacterCustomizerOpen] = useState(false);
  const [outfitBuilderOpen, setOutfitBuilderOpen] = useState(false);
  const [backgroundCustomizerOpen, setBackgroundCustomizerOpen] = useState(false);
  const [dialogueBalloonCustomizerOpen, setDialogueBalloonCustomizerOpen] = useState(false);
  const [secondaryDialogueBalloonCustomizerOpen, setSecondaryDialogueBalloonCustomizerOpen] = useState(false);
  const [artistStyleCustomizerOpen, setArtistStyleCustomizerOpen] = useState(false);
  const [characterDescription, setCharacterDescription] = useState('');
  const [outfitDescription, setOutfitDescription] = useState('');
  const [backgroundDescription, setBackgroundDescription] = useState('');
  const [dialogueBalloonDescription, setDialogueBalloonDescription] = useState('');
  const [secondaryDialogueBalloonDescription, setSecondaryDialogueBalloonDescription] = useState('');
  const [artistStyleDescription, setArtistStyleDescription] = useState('');
  const [warningOpen, setWarningOpen] = useState(false);
  const [warningMessage, setWarningMessage] = useState('');
  const [showTutorial, setShowTutorial] = useState(() => {
    const savedPreference = localStorage.getItem('showTutorial');
    return savedPreference === null ? true : JSON.parse(savedPreference);
  });
  const [tutorialStep, setTutorialStep] = useState(1);
  const [dontShowAgain, setDontShowAgain] = useState(false);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCharacterCustomizerClose = () => {
    setCharacterCustomizerOpen(false);
  };

  const handleOutfitBuilderClose = () => {
    setOutfitBuilderOpen(false);
  };

  const handleBackgroundCustomizerClose = () => {
    setBackgroundCustomizerOpen(false);
  };

  const handleCharacterCustomizerSave = (description) => {
    setCharacterDescription(description);
    setCharacterCustomizerOpen(false);
  };

  const handleOutfitBuilderSave = (description) => {
    setOutfitDescription(description);
    setOutfitBuilderOpen(false);
  };

  const handleBackgroundCustomizerSave = (description) => {
    setBackgroundDescription(description);
    setBackgroundCustomizerOpen(false);
  };

  const handleDialogueBalloonCustomizerClose = () => {
    setDialogueBalloonCustomizerOpen(false);
  };

  const handleDialogueBalloonCustomizerSave = (description) => {
    setDialogueBalloonDescription(description);
    setDialogueBalloonCustomizerOpen(false);
  };

  const handleAddSecondaryDialogueBalloon = () => {
    setSecondaryDialogueBalloonCustomizerOpen(true);
    handleMenuClose();
  };

  const handleSecondaryDialogueBalloonCustomizerSave = (description) => {
    setSecondaryDialogueBalloonDescription(`Another dialogue balloon shows, ${description}`);
    setSecondaryDialogueBalloonCustomizerOpen(false);
  };

  const handleArtistStyleCustomizerOpen = () => {
    setArtistStyleCustomizerOpen(true);
    handleMenuClose();
  };

  const handleArtistStyleCustomizerSave = (description) => {
    setArtistStyleDescription(description);
    setArtistStyleCustomizerOpen(false);
  };

  const renderAdditionalPromptArea = (label, value, onChange) => {
    if (!value) return null;
    return (
      <TextField
        label={label}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        fullWidth
        margin="normal"
        multiline
        minRows={1}
        maxRows={isMobile ? 3 : 5}
        sx={{
          width: isMobile ? '100%' : '130%',
          marginLeft: isMobile ? '0' : '-15%',
          marginRight: isMobile ? '0' : '-15%',
          marginBottom: '16px',
          '& .MuiInputBase-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
          },
          '& .MuiInputBase-input': {
            color: 'white',
          },
          '& .MuiInputLabel-root': {
            color: 'rgba(255, 255, 255, 0.7)',
          },
        }}
      />
    );
  };

  const getOrderedPrompts = () => {
    return [
      characterDescription,
      outfitDescription,
      artistStyleDescription,
      backgroundDescription,
      dialogueBalloonDescription,
      secondaryDialogueBalloonDescription
    ].filter(Boolean);
  };

  const handleAddCharacter = () => {
    setCharacterCustomizerOpen(true);
    handleMenuClose();
  };

  const handleAddOutfit = () => {
    setOutfitBuilderOpen(true);
    handleMenuClose();
  };

  const handleAddBackground = () => {
    setBackgroundCustomizerOpen(true);
    handleMenuClose();
  };

  const handleAddDialogueBalloon = () => {
    setDialogueBalloonCustomizerOpen(true);
    handleMenuClose();
  };

  const predefinedPrompts = [
    "A serene landscape with a misty mountain range at sunrise",
    "A futuristic cityscape with flying cars and neon lights",
    "A magical forest with glowing mushrooms and fairy creatures",
    "An underwater scene with colorful coral reefs and exotic fish",
    "A steampunk-inspired airship floating through cloudy skies",
    "A cozy cabin in the woods during a snowy winter night",
    "A bustling alien marketplace on a distant planet",
    "A whimsical tea party with anthropomorphic animals",
    "A post-apocalyptic urban landscape reclaimed by nature",
    "A surreal dreamscape with floating islands and impossible architecture",
    "A cyberpunk street scene with holographic advertisements",
    "A tranquil Japanese garden with cherry blossoms and a koi pond",
    "A haunted Victorian mansion on a stormy night",
    "A vibrant carnival scene with acrobats and fire breathers",
    "A prehistoric landscape with dinosaurs and ancient plants",
    "A fantasy castle perched on a cliff overlooking the sea",
    "A retro-futuristic space station orbiting a colorful nebula",
    "A mystical oasis in the middle of a vast desert",
    "An enchanted library with floating books and magical artifacts",
    "A time-traveling scene blending different historical eras"
  ];

  const handleRandomize = () => {
    const randomPrompt = predefinedPrompts[Math.floor(Math.random() * predefinedPrompts.length)];
    setPrompt(randomPrompt);
  };

  const fetcher = async (url) => {
    const { data, error } = await supabase
      .from('images')
      .select('url')
      .eq('user_id', session?.user?.id)
      .order('created_at', { ascending: false })
      .limit(20);

    if (error) throw error;
    return data;
  };

  const { data: userImages, mutate: mutateUserImages } = useSWR(
    session ? '/api/user-images' : null,
    fetcher
  );

  React.useEffect(() => {
    if (userImages) {
      const newImagePairs = userImages.reduce((acc, image, index, array) => {
        if (index % 2 === 0) {
          acc.push([image.url, array[index + 1] ? array[index + 1].url : null]);
        }
        return acc;
      }, []);

      setImagePairs(newImagePairs);
      setAllGeneratedImages(userImages.map(item => item.url));
    }
  }, [userImages]);

  const handleWarningClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setWarningOpen(false);
  };


  const handleGenerate = async (sceneData = null, comfyParams = null, progressCallback = null) => {
    const userId = session?.user?.id;
    const canGenerate = await UsageLimiter.checkLimit(userId);
    
    if (!canGenerate) {
      setWarningOpen(true);
      setWarningMessage("You've reached your usage limit. Please register to continue generating freely or wait for tomorrow. 🐸");
      return;
    }

    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const dataUrl = canvas.toDataURL('image/webp');
      setInputImage(dataUrl);
    }
    setLoading(true);
    setFakeProgress(0);
    try {
      let deploymentId, workflowInputs;

      if (sceneData) {
        const fullPrompt = `${sceneData.prompt}, ${sceneData.style} style, ${sceneData.workflow}`;
        deploymentId = "1c616401-cd2f-4b6e-805e-af10ca2f28b1";
        workflowInputs = {
          "positive": fullPrompt,
          "batch_size": batchSize,
          "scene_data": JSON.stringify(sceneData),
        };
      } else if (comfyParams) {
        deploymentId = comfyParams.deploymentId || (nsfwFilter ? "1c616401-cd2f-4b6e-805e-af10ca2f28b1" : "d0fe41d8-e1b1-4803-89e2-f71de73296ac");
        workflowInputs = {
          ...comfyParams,
          nsfw_sensitivity: nsfwFilter ? nsfwSensitivity : undefined,
        };
        delete workflowInputs.deploymentId; // Remove deploymentId from workflowInputs
      } else {
        const fullPrompt = [prompt, ...getOrderedPrompts()].filter(Boolean).join(', ');
        let weightedPrompt;
        if (insanityLevel !== 5) {
          const analyzedPhrases = promptAnalyzer.analyzePrompt(fullPrompt);
          weightedPrompt = analyzedPhrases.map(({ phrase, importance }) => {
            const weight = 1 + (importance * insanityLevel / 10);
            return `(${phrase}:${weight.toFixed(2)})`;
          }).join(', ');
        } else {
          weightedPrompt = fullPrompt;
        }

        throw new Error("Image generation service has been discontinued. Please visit the gallery to view past creations.");
      }

      if (uploadedImageUrl) {
        workflowInputs.input_simage = uploadedImageUrl;
      }

      // Add input_memory to workflowInputs if it exists
      if (comfyParams && comfyParams.input_memory) {
        workflowInputs.input_memory = comfyParams.input_memory;
      }

      throw new Error("Image generation service has been discontinued. Thank you for your support!");
      
      // Preserved for historical reference
      /*const outputs = await runComfyDeployWorkflow(
        deploymentId,
        workflowInputs,
        (progress) => {
          setFakeProgress(progress * 100);
          if (progressCallback) progressCallback(progress);
        }
      );

      const generatedImageUrls = outputs.flatMap(outputItem => {
        if (outputItem && outputItem.data && Array.isArray(outputItem.data.images)) {
          return outputItem.data.images.map(image => image.url);
        }
        return [];
      });*/

      // Service is discontinued, so we'll just return an empty result
      const generatedText = [];
      setGeneratedImages([]);
      const newPairs = [];
      // Service is discontinued, so we don't update any image arrays
      setMostRecentGeneration(prevPairs => prevPairs);
      setImagePairs(prevPairs => prevPairs);

      const incrementSuccess = await UsageLimiter.incrementUsage(userId);
      if (!incrementSuccess) {
        setWarningOpen(true);
        setWarningMessage("You've reached your daily usage limit. Please try again tomorrow.");
        setLoading(false);
        return null;
      }

      setLoading(false);
      setUploadedImageUrl(null);
      setUploadedFile(null);
      return { images: [], text: generatedText };
    } catch (error) {
      console.error('Error generating image:', error);
      setLoading(false);
      setWarningOpen(true);
      setWarningMessage("An error occurred while generating the image. Please try again.");
      throw error;
    }
  };

  const clearUploadedImage = () => {
    setUploadedImageUrl(null);
    setUploadedFile(null);
  };

  const saveImageToDatabase = async (imageUrl, nsfwFilter) => {
    const { data: { user } } = await supabase.auth.getUser();
  
    try {
      // console.log('Attempting to save image to database', { nsfwFilter });
      const imageData = {
        user_id: user ? user.id : null,
        url: imageUrl,
        prompt: prompt,
        insanity_level: insanityLevel,
        character_prompt: characterDescription,
        outfit_prompt: outfitDescription,
        background_prompt: backgroundDescription,
        dialogue_balloon_prompt: dialogueBalloonDescription,
        artist_style_prompt: artistStyleDescription,
        likes: 0,
        views: 0
      };
      // console.log('Image data:', imageData);

      // Save to images table for the user's private gallery
      const { error: privateError } = await supabase
        .from('images')
        .insert(imageData);

      if (privateError) {
        console.error('Error saving image to private gallery:', privateError);
        throw privateError;
      }

      // Save to public_images table if user is registered and NSFW filter is on
      if (user && nsfwFilter) {
        const { error: publicError } = await supabase
          .from('public_images')
          .insert(imageData);

        if (publicError) {
          console.error('Error saving image to public gallery:', publicError);
          // Don't throw error here, as we've already saved to private gallery
        }
      }
    } catch (error) {
      console.error('Error saving image to database:', error);
      alert(`Failed to save image data: ${error.message}`);
    }
  };




  useEffect(() => {
    if (inputImage) {
      const canvas = canvasRef.current;
      const img = new Image();
      img.onload = () => {
        if (canvas) {
          const ctx = canvas.getContext('2d');
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        } else {
          console.error('Canvas is not available');
        }
      };
      img.src = inputImage;
    }
  }, [inputImage]);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        const canvas = canvasRef.current;
        if (canvas) {
          const maxWidth = 1024; // Maximum width for the canvas
          const maxHeight = 1024; // Maximum height for the canvas
          let width = img.width;
          let height = img.height;

          // Calculate the new dimensions while maintaining the aspect ratio
          if (width > maxWidth) {
            height = height * (maxWidth / width);
            width = maxWidth;
          }
          if (height > maxHeight) {
            width = width * (maxHeight / height);
            height = maxHeight;
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(img, 0, 0, width, height);
        } else {
          console.error('Canvas is not available');
        }
      };
      img.src = e.target.result;
      setInputImage(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  const handleNextTutorialStep = () => {
    if (tutorialStep < 5) {
      setTutorialStep(tutorialStep + 1);
    } else {
      setShowTutorial(false);
    }
  };

  const handleSkipTutorial = () => {
    setShowTutorial(false);
    if (dontShowAgain) {
      localStorage.setItem('showTutorial', 'false');
    }
  };

  const handleDontShowAgainChange = (event) => {
    setDontShowAgain(event.target.checked);
  };


  return (
    <Box sx={{ display: 'flex', position: 'relative', marginBottom: '80px' }}>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <BackgroundImages />
      <ImageSidebar
        imagePairs={imagePairs}
        session={session}
        refreshTrigger={generatedImages}
      />
      <Box sx={{ maxWidth: 800, margin: '0 auto', flexGrow: 1, '& > *': { marginBottom: '16px' }, position: 'relative', zIndex: 1 }}>
        {showTutorial && (
          <TutorialStep
            step={tutorialStep}
            onNext={handleNextTutorialStep}
            onSkip={handleSkipTutorial}
            dontShowAgain={dontShowAgain}
            onDontShowAgainChange={handleDontShowAgainChange}
          />
        )}
      <>
        <SwitchablePromptBox
          prompt={prompt}
          setPrompt={setPrompt}
          handleRandomize={handleRandomize}
          imageDimensions={imageDimensions}
          setImageDimensions={setImageDimensions}
          handleMenuClick={handleMenuClick}
          isChatMode={isChatMode}
          setIsChatMode={setIsChatMode}
          onGenerateImage={handleGenerate}
          handleGenerate={handleGenerate}
          chatMessages={chatMessages}
          setChatMessages={setChatMessages}
          isLoading={loading}
          progress={fakeProgress}
          loadingMessage={loadingMessage}
          session={session}
        />
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleArtistStyleCustomizerOpen}>Artist & Style</MenuItem>
            <MenuItem onClick={handleAddCharacter}>Add Character</MenuItem>
            <MenuItem onClick={handleAddOutfit}>Add Outfit</MenuItem>
            <MenuItem onClick={handleAddBackground}>Add Background</MenuItem>
            <MenuItem onClick={handleAddDialogueBalloon}>Add Dialogue Balloon</MenuItem>
            {dialogueBalloonDescription && (
              <MenuItem onClick={handleAddSecondaryDialogueBalloon}>Add Secondary Dialogue Balloon</MenuItem>
            )}
          </Menu>
          {renderAdditionalPromptArea("Character Description", characterDescription, setCharacterDescription)}
          {renderAdditionalPromptArea("Outfit Description", outfitDescription, setOutfitDescription)}
          {renderAdditionalPromptArea("Background Description", backgroundDescription, setBackgroundDescription)}
          {renderAdditionalPromptArea("Dialogue Balloon", dialogueBalloonDescription, setDialogueBalloonDescription)}
          {secondaryDialogueBalloonDescription && renderAdditionalPromptArea("Secondary Dialogue Balloon", secondaryDialogueBalloonDescription, setSecondaryDialogueBalloonDescription)}
          {renderAdditionalPromptArea("Artist & Style", artistStyleDescription, setArtistStyleDescription)}
          {!isChatMode && (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2, marginBottom: '16px' }}>
              <InsanityLevelControl insanityLevel={insanityLevel} setInsanityLevel={setInsanityLevel} />
              <NSFWFilterControl
                nsfwFilter={nsfwFilter}
                setNsfwFilter={setNsfwFilter}
                nsfwSensitivity={nsfwSensitivity}
                setNsfwSensitivity={setNsfwSensitivity}
                session={session}
              />
            </Box>
          )}
          {/* Batch size functionality hidden */}
      </>
        {!isChatMode && (
          <Box sx={{ width: '70%', margin: '0 auto', mb: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Button
              onClick={() => {
                if (!prompt.trim() && !characterDescription && !outfitDescription && !backgroundDescription) {
                  setWarningMessage("Please enter your prompt before attempting to generate images");
                  setWarningOpen(true);
                } else {
                  handleGenerate();
                }
              }}
              variant="contained"
              color="primary"
              fullWidth
              disabled={loading}
              sx={{ mb: 2 }}
            >
              {loading ? <CircularProgress size={24} /> : 'Service Discontinued'}
            </Button>
            {loading && (
              <Box sx={{ width: '100%', mt: 0, mb: 2 }}>
                <LinearProgress variant="determinate" value={fakeProgress} />
              </Box>
            )}
            <Typography 
              variant="caption" 
              align="center" 
              sx={{ 
                display: 'block', 
                color: 'text.secondary', 
                fontStyle: 'italic',
                opacity: 0.7,
                animation: loading ? `${blinkAnimation} 1s linear infinite` : 'none',
              }}
            >
              {loading ? loadingMessage : 'powered by comfy deploy'}
            </Typography>
          </Box>
        )}
        {!isChatMode && (
          <DndProvider backend={HTML5Backend}>
            {mostRecentGeneration.length > 0 && (
              <ImaginationVault
                imagePairs={mostRecentGeneration}
                onSwap={(newPairs) => setMostRecentGeneration(newPairs)}
                onDelete={(index) => {
                  const newPairs = [...mostRecentGeneration];
                  newPairs.splice(index, 1);
                  setMostRecentGeneration(newPairs);
                }}
              />
            )}
          </DndProvider>
        )}
        <ZoomModal
          open={Boolean(zoomedImage)}
          onClose={handleCloseZoom}
          closeAfterTransition
        >
          <Fade in={Boolean(zoomedImage)}>
            <Box onClick={handleCloseZoom} sx={{ outline: 'none' }}>
              <ZoomImage src={zoomedImage} alt="Zoomed image" />
            </Box>
          </Fade>
        </ZoomModal>
        <CharacterCustomizer
          open={characterCustomizerOpen}
          onClose={handleCharacterCustomizerClose}
          onSave={handleCharacterCustomizerSave}
        />
        <OutfitBuilder
          open={outfitBuilderOpen}
          onClose={handleOutfitBuilderClose}
          onSave={handleOutfitBuilderSave}
        />
        <BackgroundCustomizer
          open={backgroundCustomizerOpen}
          onClose={handleBackgroundCustomizerClose}
          onSave={handleBackgroundCustomizerSave}
        />
        <DialogueBalloonCustomizer
          open={dialogueBalloonCustomizerOpen}
          onClose={handleDialogueBalloonCustomizerClose}
          onSave={handleDialogueBalloonCustomizerSave}
        />
        <DialogueBalloonCustomizer
          open={secondaryDialogueBalloonCustomizerOpen}
          onClose={() => setSecondaryDialogueBalloonCustomizerOpen(false)}
          onSave={handleSecondaryDialogueBalloonCustomizerSave}
        />
        <ArtistStyleCustomizer
          open={artistStyleCustomizerOpen}
          onClose={() => setArtistStyleCustomizerOpen(false)}
          onSave={handleArtistStyleCustomizerSave}
        />
        </Box>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={warningOpen}
          autoHideDuration={5000}
          onClose={handleWarningClose}
          message={warningMessage}
          sx={{
            bottom: { xs: 'calc(56px + 80px)', sm: 'calc(64px + 80px)' },
            zIndex: (theme) => theme.zIndex.drawer + 2,
          }}
        />
      </Box>
    );
  }


const InsanityLevelControl = ({ insanityLevel, setInsanityLevel }) => {
  const [open, setOpen] = useState(false);

  return (
    <Box sx={{ position: 'relative', display: 'inline-block' }}>
      <IconButton onClick={() => setOpen(!open)}>
        <PsychologyIcon />
        <KeyboardArrowDownIcon />
      </IconButton>
      <Typography variant="caption" display="block" align="center">
        Insanity
      </Typography>
      {open && (
        <Box sx={{ position: 'absolute', zIndex: 1, bgcolor: 'background.paper', boxShadow: 3, p: 2, mt: 1 }}>
          <Typography gutterBottom>Insanity Level</Typography>
          <Slider
            value={insanityLevel}
            onChange={(e, newValue) => setInsanityLevel(newValue)}
            min={0}
            max={10}
            step={1}
            marks={[
              { value: 0, label: 'Sane' },
              { value: 5, label: 'Moderate' },
              { value: 10, label: 'Insane' },
            ]}
            sx={{ width: 200 }}
          />
        </Box>
      )}
    </Box>
  );
};

const NSFWFilterControl = ({ nsfwFilter, setNsfwFilter, nsfwSensitivity, setNsfwSensitivity, session }) => {
  const [open, setOpen] = useState(false);

  return (
    <Box sx={{ position: 'relative', display: 'inline-block' }}>
      <Tooltip title={session ? "" : "Register to disable the filter"}>
        <span>
          <IconButton onClick={() => setOpen(!open)} disabled={!session}>
            <BlockIcon />
            <KeyboardArrowDownIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Typography variant="caption" display="block" align="center">
        NSFW Disabled
      </Typography>
      {open && (
        <Box sx={{ position: 'absolute', zIndex: 1, bgcolor: 'background.paper', boxShadow: 3, p: 2, mt: 1 }}>
          <FormControlLabel
            control={
              <Switch
                checked={nsfwFilter}
                onChange={(e) => setNsfwFilter(e.target.checked)}
                disabled={!session}
              />
            }
            label="NSFW Disabled"
          />
          {nsfwFilter && (
            <Box sx={{ width: 200, mt: 2 }}>
              <Typography gutterBottom>Sensitivity</Typography>
              <Slider
                value={nsfwSensitivity}
                onChange={(e, newValue) => setNsfwSensitivity(newValue)}
                min={0}
                max={1}
                step={0.1}
                marks={[
                  { value: 0, label: 'Low' },
                  { value: 0.5, label: 'Medium' },
                  { value: 1, label: 'High' },
                ]}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

const TutorialStep = ({ step, onNext, onSkip, dontShowAgain, onDontShowAgainChange }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const getStepContent = () => {
    switch (step) {
      case 1:
        return {
          message: "This is where you type your prompt to generate an image.",
          emoji: "👆",
          position: { top: '200px', left: '40%' },
        };
      case 2:
        return {
          message: "Check out your generated images in the My Gallery tab.",
          emoji: "👈",
          position: { top: '50%', left: '170px' },
        };
      case 3:
        return {
          message: "Use this button to add layers or options to your prompt.",
          emoji: "👉",
          position: { top: '150px', right: '350px' },
        };
      case 4:
        return {
          message: "Adjust the insanity level to control image creativity.",
          emoji: "👇",
          position: { top: '220px', left: '50%' },
        };
      case 5:
        return {
          message: "Click here to generate your image!",
          emoji: "👆",
          position: { bottom: '375px', left: '50%' },
        };
      default:
        return null;
    }
  };

  const content = getStepContent();

  if (!content) return null;

  return (
    <Fade in={true}>
      <Box
        sx={{
          position: 'fixed',
          ...content.position,
          transform: 'translate(-50%, -50%)',
          zIndex: 9999,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: isMobile ? '80%' : '300px',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            padding: '10px',
            borderRadius: '5px',
            boxShadow: '0 0 10px rgba(0,0,0,0.1)',
            marginBottom: '10px',
          }}
        >
          <Typography variant="body1" gutterBottom sx={{ color: 'black' }}>
            {content.message}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={dontShowAgain}
                  onChange={onDontShowAgainChange}
                  size="small"
                />
              }
              label={<Typography sx={{ color: 'black' }}>Don't show again</Typography>}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '8px' }}>
              <Button onClick={onSkip} size="small">
                Skip
              </Button>
              <Button onClick={onNext} endIcon={<NavigateNextIcon />} size="small">
                Next
              </Button>
            </Box>
          </Box>
        </Box>
        <Typography variant="h3">
          {content.emoji}
        </Typography>
      </Box>
    </Fade>
  );
};
