import React, { useState, useCallback, useEffect } from 'react';
import { Button, TextField, Typography, Box, Tabs, Tab, Dialog, DialogContent, DialogTitle, Snackbar, Alert } from '@mui/material';
import { supabase } from '../supabaseClient';
import { generateRandomName } from '../utils/nameGenerator';

// Add this function to get the API key
export const getApiKey = async () => {
  const { data, error } = await supabase.auth.getSession();
  if (error) {
    console.error('Error getting session:', error);
    return null;
  }
  return data.session?.access_token || null;
};

function Register({ onSuccess, onError }) {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [feedbackSeverity, setFeedbackSeverity] = useState('info');

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setFeedbackMessage('');
      const { data, error } = await supabase.auth.signUp({ email, password });
      if (error) {
        console.error('Registration error:', error);
        throw error;
      }
      if (data.user && data.user.identities && data.user.identities.length === 0) {
        console.log('User already registered:', data.user);
        setFeedbackMessage('This email is already registered. Please try logging in or use a different email.');
        setFeedbackSeverity('warning');
      } else {
        const randomName = generateRandomName();
        const { error: profileError } = await supabase
          .from('profiles')
          .upsert({ id: data.user.id, name: randomName }, { onConflict: 'id' });
        
        if (profileError) {
          console.error('Error creating profile:', profileError);
        } else {
          console.log('Profile created with random name:', randomName);
        }
        
        setFeedbackMessage('Registration successful! Please check your email for verification.');
        setFeedbackSeverity('success');
        onSuccess('Registration successful! Please check your email for verification.');
      }
    } catch (error) {
      console.error('Registration error:', error);
      const errorMessage = error.error_description || error.message || 'An error occurred during registration.';
      setFeedbackMessage(errorMessage);
      setFeedbackSeverity('error');
      onError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleRegister}>
      <TextField
        type="email"
        placeholder="Your email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        type="password"
        placeholder="Your password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        fullWidth
        margin="normal"
      />
      <Button type="submit" variant="contained" color="primary" fullWidth disabled={loading}>
        {loading ? 'Registering...' : 'Register'}
      </Button>
      {feedbackMessage && (
        <Alert severity={feedbackSeverity} sx={{ mt: 2 }}>
          {feedbackMessage}
        </Alert>
      )}
    </form>
  );

  return (
    <form onSubmit={handleRegister}>
      <TextField
        type="email"
        placeholder="Your email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        type="password"
        placeholder="Your password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        fullWidth
        margin="normal"
      />
      <Button type="submit" variant="contained" color="primary" fullWidth disabled={loading}>
        {loading ? 'Registering...' : 'Register'}
      </Button>
    </form>
  );
}

function Login({ onSuccess, onError }) {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [feedbackSeverity, setFeedbackSeverity] = useState('info');

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setFeedbackMessage('');
      
      if (!process.env.REACT_APP_SUPABASE_URL || !process.env.REACT_APP_SUPABASE_ANON_KEY) {
        throw new Error('Missing Supabase environment variables');
      }
      
      const { data, error } = await supabase.auth.signInWithPassword({ email, password });
      
      if (error) throw error;
      if (data.user) {
        const successMessage = 'Login successful!';
        setFeedbackMessage(successMessage);
        setFeedbackSeverity('success');
        onSuccess(successMessage);
      }
    } catch (error) {
      let errorMessage = 'An error occurred during login. Please try again later.';
      if (error.message === 'Failed to fetch' || error.message.includes('ERR_NAME_NOT_RESOLVED')) {
        errorMessage = 'Network error. Please check your internet connection and ensure the Supabase URL is correct.';
      } else if (error.message === 'Missing Supabase environment variables') {
        errorMessage = 'Application configuration error. Please contact the administrator.';
      } else if (error.message.includes('Invalid login credentials')) {
        errorMessage = 'Invalid email or password. Please try again.';
      }
      setFeedbackMessage(errorMessage);
      setFeedbackSeverity('error');
      if (typeof onError === 'function') {
        onError(errorMessage);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleLogin}>
      <TextField
        type="email"
        placeholder="Your email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        type="password"
        placeholder="Your password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        fullWidth
        margin="normal"
      />
      <Button type="submit" variant="contained" color="primary" fullWidth disabled={loading}>
        {loading ? 'Logging in...' : 'Login'}
      </Button>
    </form>
  );
}

export default function Auth({ open, onClose, onLoginSuccess, onError }) {
  const [tab, setTab] = useState(0);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [feedbackSeverity, setFeedbackSeverity] = useState('info');
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    // This effect runs when the component mounts
    // You can add any initialization logic here if needed
    return () => {
      // This cleanup function runs when the component unmounts
      // You can add any cleanup logic here if needed
    };
  }, []);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
    setFeedbackMessage('');
  };

  const handleSuccess = useCallback((message) => {
    setFeedbackMessage(message || 'Operation successful!');
    setFeedbackSeverity('success');
    onLoginSuccess();
    if (tab === 1) { // If it's a registration
      setShowConfirmation(true);
      setTimeout(() => {
        setShowConfirmation(false);
        onClose();
      }, 2000); // Wait for 2 seconds before closing
    } else {
      setTimeout(() => {
        onClose();
      }, 1500); // Wait for 1.5 seconds before closing for login
    }
  }, [onLoginSuccess, onClose, tab]);


  const handleError = useCallback((errorMessage) => {
    setFeedbackMessage(errorMessage);
    setFeedbackSeverity('error');
    onError(errorMessage);
  }, [onError]);

  const handleFeedbackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setFeedbackMessage('');
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
    onClose();
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
        <DialogTitle>
          <Typography variant="h4" component="div" align="center">
            Authentication
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ width: '100%' }}>
            <Tabs value={tab} onChange={handleTabChange} centered>
              <Tab label="Login" />
              <Tab label="Register" />
            </Tabs>
            {tab === 0 ? (
              <Login onSuccess={handleSuccess} onError={handleError} />
            ) : (
              <Register onSuccess={handleSuccess} onError={handleError} />
            )}
            <Snackbar 
              open={!!feedbackMessage} 
              autoHideDuration={6000} 
              onClose={handleFeedbackClose}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
              <Alert onClose={handleFeedbackClose} severity={feedbackSeverity} sx={{ width: '100%' }}>
                {feedbackMessage}
              </Alert>
            </Snackbar>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog open={showConfirmation} onClose={handleCloseConfirmation}>
        <DialogContent>
          <Typography variant="h6" align="center">
            Confirmation Email Successfully Sent! 📧
          </Typography>
          <Typography align="center" sx={{ mt: 2 }}>
            Please check your email to verify your account.
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Button onClick={handleCloseConfirmation} variant="contained" color="primary">
              Close
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
