import React, { useState, useRef, useCallback, useMemo, useEffect } from 'react';
import useSWR from 'swr';
import debounce from 'lodash/debounce';
import { Box, TextField, Button, Typography, Paper, CircularProgress, IconButton, Avatar, Snackbar, Tooltip, Switch, Fade, Alert } from '@mui/material';
import { useInterval } from '../hooks/useInterval';
import SendIcon from '@mui/icons-material/Send';
import MicIcon from '@mui/icons-material/Mic';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ImageIcon from '@mui/icons-material/Image';
import DescriptionIcon from '@mui/icons-material/Description';
import CloseIcon from '@mui/icons-material/Close';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import BrushIcon from '@mui/icons-material/Brush';
import { styled, keyframes } from '@mui/system';
import AgentMemory from './AgentMemory';
import { supabase } from '../supabaseClient';

const pulseAnimation = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
`;

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const GradientPaper = styled(Paper)(({ theme, sender }) => ({
  background: sender === 'user'
    ? 'linear-gradient(45deg, #3a3a3a 30%, #4a4a4a 90%)'
    : 'linear-gradient(45deg, #1a1a1a 30%, #2a2a2a 90%)',
  color: '#ffffff',
  padding: theme.spacing(1),
  maxWidth: '70%',
  borderRadius: '20px',
  boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
  display: 'flex',
  flexDirection: 'column',
  animation: `${fadeInAnimation} 0.3s ease-out`,
  '&:hover': {
    boxShadow: '0 5px 15px 2px rgba(0, 0, 0, .5)',
    transition: 'box-shadow 0.3s ease-in-out',
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '25px',
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
    '&.Mui-focused': {
      backgroundColor: 'rgba(255, 255, 255, 0.15)',
      boxShadow: '0 0 0 2px rgba(255, 255, 255, 0.5)',
    },
  },
  '& .MuiOutlinedInput-input': {
    padding: '12px 14px',
  },
}));

const AnimatedIconButton = styled(IconButton)(({ theme }) => ({
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.1)',
    boxShadow: '0 0 10px rgba(255, 255, 255, 0.5)',
  },
}));

const FileDisplay = ({ file }) => {
  if (!file) return null;

  if (file.type.startsWith('image/')) {
    return (
      <Box sx={{ mt: 1, maxWidth: '150px', maxHeight: '150px', overflow: 'hidden' }}>
        <img 
          src={file.content} 
          alt={file.name} 
          style={{ 
            width: '100%', 
            height: '100%', 
            objectFit: 'cover', 
            borderRadius: '5px' 
          }} 
        />
      </Box>
    );
  } else {
    const getFileEmoji = (fileType) => {
      if (fileType.includes('pdf')) return '📄';
      if (fileType.includes('doc')) return '📝';
      if (fileType.includes('txt')) return '📃';
      return '📎';
    };

    return (
      <Box sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
        <Typography variant="h6" sx={{ mr: 1 }}>{getFileEmoji(file.type)}</Typography>
        <Typography variant="body2">{file.name}</Typography>
      </Box>
    );
  }
};

const ChatComponent = ({ containerStyle = {}, onGenerateImage, session, messages, setMessages, isLoading, progress, loadingMessage, imageDimensions }) => {
  const [input, setInput] = useState('');
  const [isListening, setIsListening] = useState(false);
  const [generatedImages, setGeneratedImages] = useState([]);
  const [currentAgent, setCurrentAgent] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadedImageUrl, setUploadedImageUrl] = useState(null);
  const chatWindowRef = useRef(null);
  const fileInputRef = useRef(null);
  const [warningOpen, setWarningOpen] = useState(false);
  const [warningMessage, setWarningMessage] = useState('');
  const recognitionRef = useRef(null);
  
  // New state variables for credits
  const [creditsLeft, setCreditsLeft] = useState(10);
  const [creditsLoading, setCreditsLoading] = useState(true);
  const MAX_CREDITS_PER_DAY = 10;

  const debouncedSetCurrentAgent = useMemo(() => debounce((agent) => {
    // console.log('Agent changed:', agent);
    setCurrentAgent(agent);
    setMessages(prev => [...prev, { 
      text: agent ? `Agent switched to ${agent.name}. ${agent.prompt}` : 'Agent deleted.',
      sender: 'system', 
      timestamp: new Date() 
    }]);
  }, 500), [setMessages]);

  const memoizedSessionLog = useMemo(() => () => {
    // console.log('ChatComponent rendered. Session:', session);
  }, [session]);

  useEffect(() => {
    memoizedSessionLog();
  }, [memoizedSessionLog]);

  // Initialize Speech Recognition
  useEffect(() => {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    if (!SpeechRecognition) {
      console.warn('Speech Recognition API not supported in this browser.');
      return;
    }

    const recognition = new SpeechRecognition();
    recognition.lang = 'en-US';
    recognition.interimResults = false;
    recognition.maxAlternatives = 1;

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setInput((prev) => prev + ' ' + transcript);
    };

    recognition.onerror = (event) => {
      console.error('Speech recognition error detected: ' + event.error);
      setIsListening(false);
      setWarningMessage('Speech recognition error.');
      setWarningOpen(true);
    };

    recognition.onend = () => {
      setIsListening(false);
    };

    recognitionRef.current = recognition;
  }, []);

  const handleDrop = useCallback((event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      handleFile(file);
    }
  }, []);

  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [messages, generatedImages]);

  useEffect(() => {
    const chatWindow = chatWindowRef.current;
    if (chatWindow) {
      chatWindow.addEventListener('dragover', handleDragOver);
      chatWindow.addEventListener('drop', handleDrop);
    }
    return () => {
      if (chatWindow) {
        chatWindow.removeEventListener('dragover', handleDragOver);
        chatWindow.removeEventListener('drop', handleDrop);
      }
    };
  }, [handleDrop]);

  useEffect(() => {
    // console.log('Session in ChatComponent:', session);
  }, [session]);

  const fetcher = async (url) => {
    if (!session || !session.user) return null;

    const userId = session.user.id;
    const today = new Date().toISOString().split('T')[0]; // Format: YYYY-MM-DD

    try {
      const { data, error } = await supabase
        .from('user_credits')
        .select('credits_used')
        .eq('user_id', userId)
        .eq('date', today)
        .single();

      if (error) {
        if (error.code === 'PGRST116') { // No data found
          // Initialize credits for the day
          await supabase
            .from('user_credits')
            .insert([{ user_id: userId, date: today, credits_used: 0 }]);
          return { credits_used: 0 };
        }
        throw error;
      }

      return data;
    } catch (error) {
      console.error('Error fetching credits:', error);
      return null;
    }
  };

  const { data: credits, error: creditsError, mutate: mutateCredits } = useSWR(
    session ? '/api/user-credits' : null,
    fetcher,
    {
      refreshInterval: 30000, // Refresh every 30 seconds
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  // Ping mechanism to keep the connection alive
  const pingServer = async () => {
    try {
      await supabase.from('user_credits').select('count').limit(1);
    } catch (error) {
      console.error('Error pinging server:', error);
    }
  };

  useInterval(() => {
    pingServer();
  }, 60000); // Ping every 60 seconds

  React.useEffect(() => {
    if (credits) {
      setCreditsLeft(MAX_CREDITS_PER_DAY - credits.credits_used);
      setCreditsLoading(false);
    }
  }, [credits]);

  React.useEffect(() => {
    if (creditsError) {
      console.error('Error fetching credits:', creditsError);
      setWarningMessage('Error fetching credits.');
      setWarningOpen(true);
      setCreditsLoading(false);
    }
  }, [creditsError]);

  // Initialize Speech Recognition
  // (Duplicate useEffect removed for brevity)

  if (!session) {
    // console.warn('Session is undefined in ChatComponent');
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <CircularProgress />
      </Box>
    );
  }

  const uploadImage = async (file) => {
    if (!file || !session || !session.user) {
      console.error('File or session is missing:', { file, session });
      return null;
    }
    const fileExt = file.name.split('.').pop();
    const fileName = `${Math.random()}.${fileExt}`;
    const filePath = `${session.user.id}/${fileName}`;

    try {
      const { data, error } = await supabase.storage
        .from('generated-images')
        .upload(filePath, file, {
          cacheControl: '3600',
          upsert: false
        });

      if (error) throw error;

      const { data: { publicUrl }, error: urlError } = supabase.storage
        .from('generated-images')
        .getPublicUrl(filePath);

      if (urlError) throw urlError;

      return publicUrl;
    } catch (error) {
      console.error('Error uploading image:', error);
      console.error('It should upload to generated_images bucket');
      alert(`Error uploading image: ${error.message}`);
      return null;
    }
  };

  const handleSend = async () => {
    if (input.trim() === '' && !uploadedFile) return;

    if (creditsLoading) {
      setWarningMessage('Credits are still loading. Please wait.');
      setWarningOpen(true);
      return;
    }

    if (creditsLeft <= 0) {
      setWarningMessage('You have exhausted your daily credits.');
      setWarningOpen(true);
      return;
    }

    const newMessage = { 
      text: input, 
      sender: 'user', 
      timestamp: new Date(),
      file: uploadedFile
    };
    setMessages(prev => [...prev, newMessage]);
    setInput('');

    // Decrement credits locally
    setCreditsLeft(prev => prev - 1);

    // Update credits in Supabase
    const userId = session.user.id;
    const today = new Date().toISOString().split('T')[0]; // Format: YYYY-MM-DD

    try {
      const { data, error } = await supabase
        .from('user_credits')
        .select('credits_used')
        .eq('user_id', userId)
        .eq('date', today)
        .single();

      if (error) throw error;

      const updatedCredits = data.credits_used + 1;
      const { error: updateError } = await supabase
        .from('user_credits')
        .update({ credits_used: updatedCredits })
        .eq('user_id', userId)
        .eq('date', today);

      if (updateError) throw updateError;

      mutateCredits();
    } catch (err) {
      console.error('Unexpected error updating credits:', err);
      setWarningMessage('Unexpected error updating credits.');
      setWarningOpen(true);
    }

    try {
      const comfyParams = {
        positive: input,
        width: imageDimensions.width,
        height: imageDimensions.height,
        upscale_by: imageDimensions.upscaleBy,
        batch_size: 1,
        batch_number: 1,
        nsfw_sensitivity: 0.5,
        input_memory: currentAgent && currentAgent.prompt ? currentAgent.prompt : '',
        deploymentId: 'aba5b280-9bf0-404a-9e7c-5907304428cd'
      };

      // console.log('Current Agent:', currentAgent);
      // console.log('ComfyParams:', comfyParams);

      if (uploadedFile && uploadedFile.type.startsWith('image/')) {
        comfyParams.input_simage = uploadedFile.content;
      }

      const result = await onGenerateImage(comfyParams);
      const generatedImageUrls = result.images || [];
      const generatedText = result.text && result.text.length > 0 ? result.text[0] : "Images processed successfully!";
      setGeneratedImages(prev => [...prev, ...generatedImageUrls]);
      
      const response = { 
        text: generatedText, 
        sender: 'system', 
        timestamp: new Date(),
        images: generatedImageUrls
      };
      setMessages(prev => [...prev, response]);
    } catch (error) {
      console.error('Error processing image:', error);
      const errorResponse = { text: "Error processing image. Please try again.", sender: 'system', timestamp: new Date() };
      setMessages(prev => [...prev, errorResponse]);
    }

    setUploadedFile(null);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      handleFile(file);
    }
  };

  const handleFile = async (file) => {
    if (file.type.startsWith('image/')) {
      const imageUrl = await uploadImage(file);
      if (imageUrl) {
        setUploadedFile({
          name: file.name,
          type: file.type,
          content: imageUrl
        });
        setUploadedImageUrl(imageUrl);
      }
    } else {
      const reader = new FileReader();
      reader.onload = (e) => {
        setUploadedFile({
          name: file.name,
          type: file.type,
          content: e.target.result
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const clearUploadedImage = () => {
    setUploadedImageUrl(null);
    setUploadedFile(null);
  };

  const removeUploadedFile = () => {
    setUploadedFile(null);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleVoiceInput = () => {
    if (!recognitionRef.current) {
      setWarningMessage('Speech Recognition not supported.');
      setWarningOpen(true);
      return;
    }

    if (isListening) {
      recognitionRef.current.stop();
      setIsListening(false);
    } else {
      try {
        recognitionRef.current.start();
        setIsListening(true);
      } catch (error) {
        console.error('Error starting speech recognition:', error);
        setWarningMessage('Error starting voice input.');
        setWarningOpen(true);
      }
    }
  };

  const handleWarningClose = () => {
    setWarningOpen(false);
    setWarningMessage('');
  };

  return (
    <Box sx={{ 
      ...containerStyle, 
      display: 'flex', 
      flexDirection: 'column',
      background: 'linear-gradient(135deg, #2c2c2c 0%, #1a1a1a 100%)',
      borderRadius: '15px',
      padding: '20px',
      boxShadow: '0 10px 20px rgba(0,0,0,0.25), 0 6px 6px rgba(0,0,0,0.3)',
    }}>
      <Box sx={{ mb: 2 }}>
        <AgentMemory 
          session={session}
          currentAgent={currentAgent}
          setCurrentAgent={debouncedSetCurrentAgent}
        />
      </Box>
      
      <Paper 
        ref={chatWindowRef}
        elevation={3} 
        sx={{ 
          flex: 1, 
          overflowY: 'auto', 
          p: 2, 
          mb: 2, 
          background: 'linear-gradient(to bottom right, rgba(40,40,40,0.8), rgba(20,20,20,0.8))',
          borderRadius: '10px',
          boxShadow: 'inset 0 0 10px rgba(0,0,0,0.5)',
        }}
      >
        {messages.map((message, index) => (
          <Fade in={true} key={index}>
            <Box 
              sx={{ 
                mb: 2, 
                display: 'flex', 
                flexDirection: 'column',
                alignItems: message.sender === 'user' ? 'flex-end' : 'flex-start' 
              }}
            >
              <GradientPaper sender={message.sender}>
                <Typography variant="body1">{message.text}</Typography>
                <FileDisplay file={message.file} />
                <Typography variant="caption" sx={{ display: 'block', mt: 1, opacity: 0.7 }}>
                  {message.timestamp.toLocaleTimeString()}
                </Typography>
              </GradientPaper>
              {message.images && (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', mt: 1 }}>
                  {message.images.map((imageUrl, imgIndex) => (
                    <img 
                      key={imgIndex} 
                      src={imageUrl} 
                      alt={`Generated image ${imgIndex + 1}`} 
                      style={{ maxWidth: '45%', margin: '5px', borderRadius: '10px' }}
                    />
                  ))}
                </Box>
              )}
            </Box>
          </Fade>
        ))}
        {isLoading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', mt: 2 }}>
            <CircularProgress size={24} sx={{ animation: `${pulseAnimation} 1.5s infinite` }} />
            <Typography variant="caption" sx={{ mt: 1 }}>
              {loadingMessage} {progress.toFixed(0)}%
            </Typography>
          </Box>
        )}
      </Paper>
      <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
        {uploadedFile && (
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            {uploadedFile.type.startsWith('image/') ? (
              <Box 
                sx={{ 
                  width: 80, 
                  height: 80, 
                  mr: 1, 
                  position: 'relative',
                  borderRadius: '8px',
                  overflow: 'hidden',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                  '&:hover::before': {
                    content: `"${uploadedFile.name}"`,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    color: 'white',
                    padding: '4px',
                    fontSize: '0.75rem',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    zIndex: 1
                  }
                }}
              >
                <img 
                  src={uploadedFile.content} 
                  alt="Uploaded file"
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
                <AnimatedIconButton 
                  size="small" 
                  onClick={removeUploadedFile}
                  sx={{ 
                    position: 'absolute', 
                    top: 2, 
                    right: 2, 
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    padding: '2px',
                    '&:hover': {
                      backgroundColor: 'rgba(0,0,0,0.7)',
                    }
                  }}
                >
                  <CloseIcon fontSize="small" sx={{ color: 'white', fontSize: '0.8rem' }} />
                </AnimatedIconButton>
              </Box>
            ) : (
              <>
                <Typography variant="body2" sx={{ mr: 1 }}>
                  {uploadedFile.name}
                </Typography>
                <AnimatedIconButton size="small" onClick={removeUploadedFile}>
                  <CloseIcon fontSize="small" />
                </AnimatedIconButton>
              </>
            )}
          </Box>
        )}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <StyledTextField
            fullWidth
            variant="outlined"
            placeholder="Type your message here..."
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && handleSend()}
            sx={{ mr: 1 }}
            disabled={creditsLeft <= 0}
          />
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileUpload}
            accept="image/*,.pdf,.doc,.docx,.txt"
          />
          <Tooltip title="Attach a file">
            <AnimatedIconButton 
              color="primary" 
              onClick={() => fileInputRef.current.click()}
              sx={{ mr: 1 }}
              disabled={creditsLeft <= 0}
            >
              <AttachFileIcon />
            </AnimatedIconButton>
          </Tooltip>
          <Tooltip title={isListening ? "Stop Listening" : "Start Voice Input"}>
            <AnimatedIconButton 
              color={isListening ? "secondary" : "primary"} 
              onClick={handleVoiceInput}
              disabled={!('SpeechRecognition' in window) && !('webkitSpeechRecognition' in window) || creditsLeft <= 0}
              sx={{ mr: 1 }}
            >
              <MicIcon />
            </AnimatedIconButton>
          </Tooltip>
          <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <Tooltip title="Daily credits remaining">
              <Button 
                variant="contained" 
                color="primary" 
                endIcon={<SendIcon />} 
                onClick={handleSend}
                disabled={isLoading || creditsLeft <= 0}
                sx={{ 
                  borderRadius: '50px',
                  transition: 'all 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: '0 0 15px rgba(255, 255, 255, 0.3)',
                  },
                  '&:active': {
                    transform: 'scale(0.95)',
                  },
                  minWidth: '100px',
                  height: '40px',
                }}
              >
                Send
              </Button>
            </Tooltip>
            {creditsLoading ? (
              <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: -12,
                  right: -12,
                  color: 'secondary.main',
                }}
              />
            ) : (
              <Box
                sx={{
                  position: 'absolute',
                  top: -10,
                  right: -10,
                  bgcolor: 'background.paper',
                  borderRadius: '50%',
                  padding: '2px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                }}
              >
                <Typography
                  variant="caption"
                  sx={{
                    fontWeight: 'bold',
                    color: 'primary.main',
                    fontSize: '0.7rem',
                  }}
                >
                  {creditsLeft}💎
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={warningOpen}
        autoHideDuration={3000}
        onClose={handleWarningClose}
      >
        <Alert onClose={handleWarningClose} severity="warning" sx={{ width: '100%' }}>
          {warningMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ChatComponent;
