import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Box, Slider, Grid, Typography, Modal, Card, CardContent, CardMedia, CircularProgress, useMediaQuery, useTheme, IconButton, Collapse, Tooltip, Fade } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import { supabase } from '../supabaseClient';
import { styled } from '@mui/system';
import Meteors from './Meteors';
import Particles from './Particles';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: expand ? 'rotate(180deg)' : 'rotate(0deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const StyledImage = styled('img')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  transition: 'filter 0.3s ease-in-out',
  '&:hover': {
    filter: 'brightness(1.1) contrast(1.1)',
  },
}));

const Gallery = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [images, setImages] = useState([]);
  const [imagesPerRow, setImagesPerRow] = useState(4);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loadingFullImage, setLoadingFullImage] = useState(false);
  const [visibleImages, setVisibleImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedMetadata, setExpandedMetadata] = useState(false);
  const containerRef = useRef(null);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      // You can add a notification here if you want
      console.log('Metadata copied to clipboard');
    });
  };

  const fetchImages = useCallback(async () => {
    setLoading(true);
    try {
      const { data: { session }, error: sessionError } = await supabase.auth.getSession();
      if (sessionError) {
        console.error('Error getting session:', sessionError);
        setLoading(false);
        return;
      }

      if (!session) {
        setLoading(false);
        return;
      }

      const { data, error } = await supabase
        .from('images')
        .select('*')
        .eq('user_id', session.user.id)
        .order('created_at', { ascending: false });

      if (error) {
        console.error('Error fetching images:', error);
      } else {
        setImages(data);
      }
    } catch (error) {
      console.error('Unexpected error during image fetch:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchImages();
  }, [fetchImages]);

  const handleSliderChange = useCallback((event, newValue) => {
    setImagesPerRow(newValue);
  }, []);

  const handleImageClick = useCallback((image) => {
    setSelectedImage(image);
    setLoadingFullImage(true);
  }, []);

  const handleImageLoad = useCallback(() => {
    setLoadingFullImage(false);
  }, []);

  const handleCloseModal = useCallback(() => {
    setSelectedImage(prevImage => {
      if (prevImage) {
        setTimeout(() => setSelectedImage(null), 300); // Delay to allow fade-out animation
        return { ...prevImage, closing: true };
      }
      return null;
    });
  }, []);

  const handleDeleteImage = useCallback(async (imageId, event) => {
    event.stopPropagation();
    if (window.confirm('Are you sure you want to delete this image?')) {
      try {
        const { error } = await supabase
          .from('images')
          .delete()
          .eq('id', imageId);

        if (error) throw error;

        setImages(prevImages => prevImages.filter(img => img.id !== imageId));
        if (selectedImage && selectedImage.id === imageId) {
          setSelectedImage(null);
        }
      } catch (error) {
        console.error('Error deleting image:', error);
        alert('Failed to delete image. Please try again.');
      }
    }
  }, [selectedImage]);


  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        setVisibleImages((prevVisible) => {
          const newVisible = new Set(prevVisible);
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              newVisible.add(entry.target.dataset.id);
            } else {
              newVisible.delete(entry.target.dataset.id);
            }
          });
          return Array.from(newVisible);
        });
      },
      { rootMargin: '100px' }
    );

    const imageElements = containerRef.current?.querySelectorAll('[data-id]');
    imageElements?.forEach((element) => {
      observer.observe(element);
    });

    return () => {
      observer.disconnect();
    };
  }, [images]);

  return (
    <Box sx={{ padding: 2 }} ref={containerRef}>
      <Typography variant="h4" component="h2" gutterBottom>
      </Typography>
      {!isMobile && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mb: 2 }}>
          <Box sx={{ width: 200 }}>
            <Typography id="images-per-row-slider" gutterBottom>
              Images per row
            </Typography>
            <Slider
              value={imagesPerRow}
              onChange={handleSliderChange}
              aria-labelledby="images-per-row-slider"
              step={1}
              marks
              min={2}
              max={6}
            />
          </Box>
        </Box>
      )}
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 450 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={2}>
          {images.map((image, index) => (
            <Grid item xs={12} sm={6} md={12 / imagesPerRow} key={image.id}>
              <Box
                sx={{
                  position: 'relative',
                  paddingTop: '100%',
                  cursor: 'pointer',
                  overflow: 'hidden',
                  borderRadius: theme.shape.borderRadius,
                  boxShadow: theme.shadows[4],
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
                onClick={() => handleImageClick(image)}
                data-id={image.id}
              >
                {visibleImages.includes(image.id) && (
                  <Fade in={true} timeout={500}>
                    <Box>
                      <StyledImage
                        src={image.url}
                        alt={`Generated image ${index + 1}`}
                        loading="lazy"
                      />
                      <IconButton
                        onClick={(e) => handleDeleteImage(image.id, e)}
                        sx={{
                          position: 'absolute',
                          top: 8,
                          right: 8,
                          backgroundColor: 'rgba(0, 0, 0, 0.5)',
                          color: 'white',
                          '&:hover': {
                            backgroundColor: 'rgba(255, 0, 0, 0.7)',
                          },
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Fade>
                )}
              </Box>
            </Grid>
          ))}
        </Grid>
      )}
      <Modal
        open={!!selectedImage}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleCloseModal();
          }
        }}
        aria-labelledby="image-modal-title"
        aria-describedby="image-modal-description"
        closeAfterTransition
      >
        <Fade in={!!selectedImage && !selectedImage.closing} timeout={300}>
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: 800,
            maxHeight: '90vh',
            bgcolor: 'rgba(0, 0, 0, 0.9)',
            boxShadow: 24,
            p: 4,
            borderRadius: theme.shape.borderRadius,
            overflow: 'auto',
          }}>
            <IconButton
              onClick={handleCloseModal}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'white',
              }}
            >
              <CloseIcon />
            </IconButton>
            <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, overflow: 'hidden', pointerEvents: 'none' }}>
              <Meteors />
              <Particles />
            </Box>
            <Box sx={{ position: 'relative', zIndex: 1 }}>
            {loadingFullImage && (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
                <CircularProgress />
              </Box>
            )}
            {selectedImage && (
              <CardMedia
                component="img"
                image={selectedImage.url}
                alt={selectedImage.prompt}
                sx={{ 
                  maxHeight: '70vh', 
                  objectFit: 'contain', 
                  borderRadius: theme.shape.borderRadius,
                  mb: 2,
                }}
                onLoad={handleImageLoad}
              />
            )}
            <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography id="image-modal-title" variant="h6" component="h2">
                  Image Details
                </Typography>
                <Tooltip title="Copy all metadata">
                  <IconButton onClick={() => copyToClipboard(JSON.stringify(selectedImage, null, 2))}>
                    <ContentCopyIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <Typography id="image-modal-description" sx={{ mb: 2 }}>
                Prompt: {selectedImage?.prompt}
              </Typography>
              <ExpandMore
                expand={expandedMetadata}
                onClick={() => setExpandedMetadata(!expandedMetadata)}
                aria-expanded={expandedMetadata}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </ExpandMore>
              <Collapse in={expandedMetadata} timeout="auto" unmountOnExit>
                <Typography>
                  Character Prompt: {selectedImage?.character_prompt || 'None'}
                </Typography>
                <Typography>
                  Outfit Prompt: {selectedImage?.outfit_prompt || 'None'}
                </Typography>
                <Typography>
                  Background Prompt: {selectedImage?.background_prompt || 'None'}
                </Typography>
                <Typography>
                  Dialogue Balloon: {selectedImage?.dialogue_balloon_prompt || 'None'}
                </Typography>
                <Typography>
                  Artist & Style: {selectedImage?.artist_style_prompt || 'None'}
                </Typography>
                <Typography>
                  Negative Prompt: {selectedImage?.negative_prompt || 'None'}
                </Typography>
                <Typography>
                  Change Factor: {selectedImage?.change_factor}
                </Typography>
                <Typography>
                  Created At: {new Date(selectedImage?.created_at).toLocaleString()}
                </Typography>
              </Collapse>
            </CardContent>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default React.memo(Gallery);
