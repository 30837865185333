import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Box, Typography, Grid, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import ImageCard from './ImageCard';
import { DndProvider, useDragDropManager } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import Meteors from './Meteors';
import Particles from './Particles';

const BackgroundContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  pointerEvents: 'none',
}));

const ResponsiveBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const ResponsiveTypography = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontWeight: 'bold',
  color: theme.palette.primary.light,
  marginBottom: theme.spacing(5),
  position: 'relative',
  zIndex: 1,
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
    marginBottom: theme.spacing(3),
  },
}));

const ImaginationVault = React.memo(({ imagePairs, onSwap, onDelete }) => {
  const [cards, setCards] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const dragDropManager = useDragDropManager();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setCards(imagePairs.slice(0, 4).map((pair, index) => ({ id: index, pair, showTransparent: false })));
  }, [imagePairs]);

  useEffect(() => {
    const unsubscribe = dragDropManager.getMonitor().subscribeToStateChange(() => {
      setIsDragging(dragDropManager.getMonitor().isDragging());
    });
    return unsubscribe;
  }, [dragDropManager]);

  const handleSwap = useCallback((dragIndex, hoverIndex) => {
    setCards((prevCards) => {
      const newCards = [...prevCards];
      [newCards[dragIndex], newCards[hoverIndex]] = [newCards[hoverIndex], newCards[dragIndex]];
      onSwap(newCards.map(card => card.pair));
      return newCards;
    });
  }, [onSwap]);

  const handleToggleTransparent = useCallback((index) => {
    setCards((prevCards) => {
      const newCards = [...prevCards];
      newCards[index] = { ...newCards[index], showTransparent: !newCards[index].showTransparent };
      return newCards;
    });
  }, []);

  const handleDelete = useCallback((index) => {
    onDelete(index);
  }, [onDelete]);

  const memoizedCards = useMemo(() => cards.map((card, index) => (
    <Grid item xs={12} sm={6} md={6} lg={6} key={card.id}>
      <ImageCard
        index={index}
        id={card.id}
        pair={card.pair}
        showTransparent={card.showTransparent}
        onSwap={handleSwap}
        onDelete={() => handleDelete(index)}
        onToggleTransparent={() => handleToggleTransparent(index)}
        isDragging={isDragging}
      />
    </Grid>
  )), [cards, handleSwap, handleDelete, handleToggleTransparent, isDragging]);

  return (
    <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
      <ResponsiveBox>
        <BackgroundContainer>
          <Meteors count={isMobile ? 50 : 100} />
          <Particles count={isMobile ? 25 : 50} />
        </BackgroundContainer>
        <ResponsiveTypography variant={isMobile ? "h6" : "h5"} gutterBottom>
          Imagination Vault
        </ResponsiveTypography>
        <Grid container spacing={isMobile ? 2 : 3} justifyContent="center" sx={{ position: 'relative', zIndex: 1 }}>
          {memoizedCards}
        </Grid>
      </ResponsiveBox>
    </DndProvider>
  );
});

export default ImaginationVault;
