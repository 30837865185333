import React, { useState, useRef, useCallback } from 'react';
import { Box, IconButton, Tooltip, Switch } from '@mui/material';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { useDrag, useDrop } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { DndProvider } from 'react-dnd';

const CardContainer = styled(Box)(({ theme, isDragging }) => ({
  position: 'relative',
  width: '100%',
  paddingTop: '100%',
  borderRadius: '12px',
  overflow: 'hidden',
  boxShadow: isDragging 
    ? '0 0 0 2px #4caf50, 0 8px 16px rgba(0, 0, 0, 0.3)'
    : '0 8px 16px rgba(0, 0, 0, 0.3)',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: isDragging ? 'none' : 'scale(1.05)',
    boxShadow: isDragging 
      ? '0 0 0 2px #4caf50, 0 8px 16px rgba(0, 0, 0, 0.3)'
      : '0 12px 24px rgba(0, 0, 0, 0.4)',
  },
  cursor: 'move',
  opacity: isDragging ? 0.6 : 1,
  '@media (max-width: 600px)': {
    paddingTop: '75%', // Adjust aspect ratio for mobile
  },
}));

const CardContent = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
});

const CardImage = styled('img')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  transition: 'opacity 0.5s ease-in-out, transform 0.3s ease-in-out',
});

const CardOverlay = styled(Box)(({ theme, isDragging }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  padding: '12px',
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  transition: isDragging ? 'none' : 'transform 0.3s ease-in-out',
  transform: isDragging ? 'translateY(0)' : 'translateY(100%)',
  '.MuiBox-root:hover > &': {
    transform: 'translateY(0)',
  },
}));

const ZoomOverlay = styled(Box)({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.9)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 9999,
});

const ZoomImage = styled('img')({
  maxWidth: '90%',
  maxHeight: '90%',
  objectFit: 'contain',
});

const ImageCard = React.memo(({ id, index, pair, showTransparent, onSwap, onDelete, onToggleTransparent }) => {
  const [isZoomed, setIsZoomed] = useState(false);
  const [isChanging, setIsChanging] = useState(false);
  const ref = useRef(null);

  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    item: { id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: 'card',
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
    
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Get the bounding rectangle of the hover target
      const hoverBoundingRect = ref.current.getBoundingClientRect();

      // Get the middle of the card
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Get the mouse position
      const clientOffset = monitor.getClientOffset();

      // Get the pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      onSwap(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  drag(drop(ref));

  const handleToggle = useCallback(() => {
    setIsChanging(true);
    onToggleTransparent();
    setTimeout(() => setIsChanging(false), 500); // Match this with the transition duration
  }, [onToggleTransparent]);

  const handleZoom = useCallback(() => {
    setIsZoomed(true);
  }, []);

  const handleCloseZoom = useCallback(() => {
    setIsZoomed(false);
  }, []);

  // Sort the pair to ensure the default image is first
  const sortedPair = pair.sort((a, b) => {
    const aIsDefault = a.includes('_default_');
    const bIsDefault = b.includes('_default_');
    return aIsDefault ? -1 : bIsDefault ? 1 : 0;
  });

  return (
    <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
      <CardContainer ref={ref} style={{ opacity: isDragging ? 0.5 : 1 }} isDragging={isDragging}>
        <CardContent>
          <CardImage
            src={sortedPair[0]}
            alt={`Generated Image ${index + 1} (Default)`}
            loading="lazy"
            style={{
              opacity: showTransparent ? 0 : 1,
              transform: isChanging ? 'scale(1.1)' : 'scale(1)',
            }}
          />
          <CardImage
            src={sortedPair[1]}
            alt={`Generated Image ${index + 1} (Transparent)`}
            loading="lazy"
            style={{
              opacity: showTransparent ? 1 : 0,
              transform: isChanging ? 'scale(1.1)' : 'scale(1)',
            }}
          />
        </CardContent>
        <CardOverlay isDragging={isDragging}>
          <Tooltip title="Toggle Transparency" arrow>
            <Switch
              checked={showTransparent}
              onChange={handleToggle}
              color="primary"
              size="small"
            />
          </Tooltip>
          <Tooltip title="Zoom Image" arrow>
            <IconButton size="small" onClick={handleZoom} sx={{ color: 'white' }}>
              <ZoomInIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete Image" arrow>
            <IconButton size="small" onClick={onDelete} sx={{ color: 'white' }}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </CardOverlay>
      </CardContainer>
      {isZoomed && (
        <ZoomOverlay onClick={handleCloseZoom}>
          <ZoomImage src={showTransparent ? pair[1] : pair[0]} alt={`Zoomed Image ${index + 1}`} />
        </ZoomOverlay>
      )}
    </DndProvider>
  );
});

export default ImageCard;
