import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { supabase } from '../supabaseClient';

const BackgroundImages = ({ show }) => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    fetchTopImages();
  }, []);

  const fetchTopImages = async () => {
    const { data, error } = await supabase
      .from('public_images')
      .select('url')
      .order('likes', { ascending: false })
      .limit(15);

    if (error) {
      // console.error('Error fetching top images:', error);
    } else {
      setImages(data.map(img => img.url));
    }
  };

  if (!show) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: -1,
        display: 'grid',
        gridTemplateColumns: 'repeat(5, 1fr)',
        gridTemplateRows: 'repeat(1, 1fr)',
        gap: '0px',
        padding: '0px',
        opacity: 0.15,
      }}
    >
      {images.slice(0, 5).map((url, index) => (
        <Box
          key={index}
          sx={{
            backgroundImage: `url(${url})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            filter: 'brightness(0.8) contrast(1.2)',
          }}
        />
      ))}
    </Box>
  );
};

export default BackgroundImages;
