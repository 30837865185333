import { ComfyDeploy } from 'comfydeploy';

const client = new ComfyDeploy({
  bearerAuth: process.env.REACT_APP_COMFY_DEPLOY_SDK_API_KEY,
});

// Example DeploymentId for the chat workflow
export const CHAT_WORKFLOW_DEPLOYMENT_ID = 'a1b2c3d4-e5f6-7890-abcd-1234567890ab';

export const runComfyDeployWorkflow = async (deploymentId, inputs, onProgressUpdate) => {
  try {
    const result = await client.run.create({
      deploymentId: deploymentId,
      inputs: {
        ...inputs,
        width: inputs.width || 1344,
        height: inputs.height || 768,
        batch_number: inputs.batch_size || 1,
        upscale_by: inputs.upscale_by || 1.5,
      },
    });

    if (result && result.runId) {
      const runId = result.runId;
      let runResult;
      let attempts = 0;
      const maxAttempts = 90; // Increased to 90 attempts (3 minutes with 2-second intervals)
      do {
        await new Promise(resolve => setTimeout(resolve, 2000));
        try {
          runResult = await client.run.get({ runId });
          
          if (runResult.status === 'success') {
            return runResult.outputs;
          } else if (runResult.status === 'failed') {
            throw new Error(`Workflow failed: ${runResult.error || 'Unknown error'}`);
          }
          
          if (runResult.progress !== undefined) {
            onProgressUpdate(runResult.progress);
          }
          
          attempts++;
        } catch (pollError) {
          attempts++;
          if (attempts >= maxAttempts) {
            throw new Error('Max polling attempts reached');
          }
        }
      } while (attempts < maxAttempts);

      throw new Error('Workflow did not complete within 3 minutes');
    } else {
      throw new Error('Invalid response from Comfy Deploy');
    }
  } catch (error) {
    if (error.response && error.response.data) {
      const errorMessage = error.response.data.error || JSON.stringify(error.response.data);
      if (errorMessage === 'Workflow not found') {
        throw new Error(`Workflow not found. Please check your deployment ID and API key. Deployment ID: ${deploymentId}`);
      } else {
        throw new Error(`Comfy Deploy API error: ${errorMessage}`);
      }
    } else if (error instanceof Error) {
      throw error;
    } else {
      throw new Error(`Unexpected Comfy Deploy error: ${JSON.stringify(error)}`);
    }
  }
};
